import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import ProductFilter from "../ProductFilter";
import ProductCard from "../ProductCard";
import './ProductList.css';
import {Product} from "../../Model/Product";
import {assertTokenValidity} from "../../common/ReztoToken";
import {useTranslation} from "react-i18next";
import {userReztoStore} from "../../state/rootStore";
import {FilterState} from "../../state/FilterSlice";

function ProductList() {

    const {reztoAuth, productStateSlice, filterStateSlice} = userReztoStore();
    const history = useHistory();
  

    const filterState: FilterState = filterStateSlice;
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const {t} = useTranslation();

    useEffect(() => {
        if (restaurantId) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    productStateSlice.fetchProducts(restaurantId, reztoAuth);
                });
        }
    }, [reztoAuth, restaurantId]);

    const [filteredProducts, setFilteredProducts] = useState(productStateSlice.products);
    useEffect(() => {
        if(filterState.productFilter === "" || filterState.productFilter === undefined){
            setFilteredProducts(productStateSlice.products)
        }else if(filterState.productFilter){
            setFilteredProducts(
                productStateSlice.products.filter((product: Product) => product.categories !== null
                    && product.categories.includes(filterState.productFilter!)))
        }
    }, [productStateSlice.products, filterStateSlice.productFilter])


    return (
        <>

            <div className="flex flex-row bg-white justify-between sticky top-0 z-40 pr-5">
                
                <div className="flex flex-col justify-top">
                    <h1 className="text-header mb-2 mt-10">{t('PRODUCT_HEADER').toUpperCase()}</h1>
                    <h1 className="text-normal text-xl">{t('PRODUCT_SUB')}</h1>
                </div>
                <div className="flex flex-row items-center">
                    <button
                        onClick={() => {
                            history.push("/restaurants/" + restaurantId + "/products/create")
                        }}
                        className="dropdown-button h-10 mt-2">Ny produkt
                    </button>
                    <ProductFilter/>
                </div>


            </div>

            <hr/>

            <div className="flex flex-wrap mt-24 pt-5 gap-y-32">
                {
                    filteredProducts.map((product: Product) => {
                        return (
                            <ProductCard key={product.id} product={product}/>
                        )
                    })
                }
            </div>


        </>

    );
}

export default ProductList;
