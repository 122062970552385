import * as React from 'react';
import FormDialog from "../Modal/FormDialog";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {MenuModel} from "../Model/Menu";
import {userReztoStore} from "../state/rootStore";

export default function CopyMenuForm({onClick, onDismiss}: { onClick: any, onDismiss: any }) {
    const {register, handleSubmit} = useForm({});
    const {menuModelStateSlice} = userReztoStore();
    const menuModels: MenuModel[] = menuModelStateSlice.menuModels;
    const {t} = useTranslation();

    const onSubmit = (data: any) => {
        onClick(data);
        onDismiss();
    }

    return (
        <FormDialog title={t('COPY_MENU')}
                    open={true} setOpen={onDismiss}
        >
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-between h-full w-full">
                <div className="flex flex-row flex-wrap">
                    {
                        menuModels.map((menuModel: MenuModel) => {
                            return (
                                <label key={menuModel.id} className="p-2 text-2xl text-mainFont">
                                    <input type="radio" className="" value={menuModel.id}
                                           {...register('menuModelId')} />
                                    {menuModel.name}
                                </label>
                            )
                        })
                    }
                </div>
                <div className="flex flex-row justify-between p-5 ">
                    <button type="button"
                            onClick={() => onDismiss(false)}
                            className="dropdown-button">Avbryt
                    </button>
                    <button type="submit" className="dropdown-button">Spara</button>
                </div>
            </form>
        </FormDialog>
    );

}