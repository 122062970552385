import {userReztoStore} from "../state/rootStore";
import {RestaurantChainAggregateState} from "../state/RestaurantChainAggregateSlice";
import {RestaurantChain} from "../Model/RestaurantChainAggregate";
import {Dispatch, SetStateAction, useState} from "react";
import {UseFormReturnType} from "@mantine/form/lib/use-form";
import {UserRestaurantChain} from "../Model/UserModel";
import {REZTO_ACCESS_ROLES} from "../common/Constants";


export function UserRestaurantSelection({
                                            restaurantChainId,
                                            setShowModal,
                                            form,
                                            userAccessRole
                                        }: {
    restaurantChainId: string, setShowModal: Dispatch<SetStateAction<boolean>>,
    form: any, userAccessRole: any
}) {


    const {restaurantChainAggregateState} = userReztoStore();
    const chainAggregate: RestaurantChainAggregateState = restaurantChainAggregateState;

    const initialRestaurants = chainAggregate.restaurants.filter(r => r.restaurantChainId === restaurantChainId).map(restaurant => {
        let checked = false;
        const previousChain = form.values.restaurantChains.find((r: any) => r.id === restaurantChainId)
        if(previousChain){
            if(previousChain.restaurants.map((r: any) => r.id).includes(restaurant.id)){
                checked = true;
            }
        }
        return {label: restaurant.name, checked: checked, key: restaurant.id};
    })
    const restaurantChain: RestaurantChain | undefined = chainAggregate.restaurantChains.find(r => r.id === restaurantChainId);


    const [restaurantSelectionState, setRestaurantSelectionState] = useState(
        {
            restaurantChain: {id: restaurantChain!.id, name: restaurantChain!.name, checked: form.values.restaurantChains.filter((r: any) => r.id === restaurantChainId).length > 0},
            restaurants: initialRestaurants
        })

    if (!restaurantSelectionState.restaurantChain) {
        return <></>
    }


    return (
        <div className="p-5">
            <div onClick={() => {
                const isChecked = restaurantSelectionState.restaurantChain.checked;
                if (isChecked) {
                    setRestaurantSelectionState(
                        {
                            ...restaurantSelectionState,
                            restaurants: restaurantSelectionState.restaurants.map(r => ({...r, checked: false})),
                            restaurantChain: {...restaurantSelectionState.restaurantChain, checked: !isChecked}
                        })
                } else {
                    setRestaurantSelectionState({
                        ...restaurantSelectionState,
                        restaurants: restaurantSelectionState.restaurants.map(r => ({...r, checked: true})),
                        restaurantChain: {...restaurantSelectionState.restaurantChain, checked: !isChecked}
                    })
                }

            }} className="flex flex-row justify-between select-none items-center user-page-list-item border-2 p-2 mt-2">
                <h1>{restaurantSelectionState.restaurantChain.name}</h1>
                <input onChange={() => {}} value={restaurantSelectionState.restaurantChain.id}
                       checked={restaurantSelectionState.restaurantChain.checked} type="checkbox"/>
            </div>
            {userAccessRole !== REZTO_ACCESS_ROLES.RESTAURANT_CHAIN_OWNER &&
                <>
                    {
                        restaurantSelectionState.restaurants.map(restaurant => {
                            return <div key={restaurant.key} onClick={() => {
                                let rests = restaurantSelectionState.restaurants.map(r => r.key === restaurant.key ? {
                                    ...r,
                                    checked: !r.checked
                                } : r);
                                if (rests.filter(r => r.checked).length == 0) {
                                    setRestaurantSelectionState({
                                        ...restaurantSelectionState,
                                        restaurantChain: {...restaurantSelectionState.restaurantChain, checked: false},
                                        restaurants: [...rests]
                                    })
                                } else {
                                    setRestaurantSelectionState({
                                        ...restaurantSelectionState,
                                        restaurantChain: {...restaurantSelectionState.restaurantChain, checked: true},
                                        restaurants: [...rests]
                                    })
                                }

                            }
                            }
                                        className="flex flex-row justify-between select-none items-center user-page-list-item border-2 p-2 mt-2">
                                <h1>{restaurant.label}</h1>
                                <input value={restaurant.key} onChange={() => {}} checked={restaurant.checked}
                                       type="checkbox"/>
                            </div>
                        })
                    }
                    </>

            }

            <button onClick={() => {
                if(restaurantSelectionState.restaurantChain.checked){
                    const index = form.values.restaurantChains.map((rc: any) => rc.id).indexOf(restaurantSelectionState.restaurantChain.id);
                    if (index > -1) {
                        form.values.restaurantChains.splice(index, 1);
                    }

                    let restaurants = restaurantSelectionState.restaurants.filter(r => r.checked).map(r => ({id: r.key}))
                    form.values.restaurantChains = [...form.values.restaurantChains, {id: restaurantSelectionState.restaurantChain.id,
                        restaurants: userAccessRole === REZTO_ACCESS_ROLES.RESTAURANT_CHAIN_OWNER ? [] : restaurants}]
                    setShowModal(false);
                }else{
                    const index = form.values.restaurantChains.map((rc: any) => rc.id).indexOf(restaurantSelectionState.restaurantChain.id);
                    if (index > -1) {
                        form.values.restaurantChains.splice(index, 1);
                    }
                    setShowModal(false);
                }
            }} className="dropdown-button absolute bottom-2 right-2">Spara
            </button>
            <button onClick={() => setShowModal(false)} className="dropdown-button absolute bottom-2 left-2">Avbryt
            </button>
        </div>
    );
}