import React from 'react';
import LazyLoad from 'react-lazyload';
import { useParams} from 'react-router';
import ReztoImage from "../common/Image/ReztoImage";
import {Product} from "../Model/Product";
import {Link} from 'react-router-dom'

export default function ProductCard({product}: { product: Product }) {
    const {restaurantId} = useParams<{ restaurantId: string }>();

    return (
        <LazyLoad height={300} once>
            <Link
                to={"/restaurants/" + restaurantId + "/products/" + product.id + "/edit"}>
            <div key={product.id} className="product-card border-solid border-2 rounded-3xl">
                <div className="absolute -top-24 left-12">
                    <ReztoImage classes="rounded-2xl" imageUrl={product.imageUrl} height='180px' width='180px'/>
                </div>
                <div className="h-1/3"/>

                <div className="flex flex-col p-5 justify-between">
                    <div
                        className="text-subheader text-2xl mb-2 overflow-ellipsis overflow-hidden whitespace-nowrap">
                        {product.name}</div>
                        {product.desc &&
                            <p className="text-normal ">{product.desc.length > 50 ?
                                (product.desc.substring(0, 50) + "...") : product.desc}</p>
                        }
                        {!product.desc &&
                            <p className="text-normal ">{product.selectableIngredients?.join(", ").length > 50 ?
                                (product.selectableIngredients?.join(", ").substring(0, 50) + "...") :
                                product.selectableIngredients?.join(", ")}
                            </p>
                        }
                        <p className="text-header text-2xl absolute bottom-3 left-5 ">{product.prices[0].amount.amount / 100} kr</p>


                </div>
            </div>
            </Link>
        </LazyLoad>
    )
}
