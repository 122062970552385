import React from 'react'
import {useForm} from 'react-hook-form';
import InputField from '../../common/InputField';
import {assertTokenValidity} from "../../common/ReztoToken";
import {userReztoStore} from "../../state/rootStore";

export default function MenuCategoryForm({menuModelId, menuId, setCreateDialogOpen}:
                                             { menuModelId: string, menuId: string, setCreateDialogOpen: any }) {
  
    const {reztoAuth, menuModelStateSlice} = userReztoStore();

    const {handleSubmit, control, formState: {}} = useForm({
        defaultValues: {
            name: ""
        }
    });


    const onSub = (data: any) => {
        assertTokenValidity(reztoAuth)
            .then((_) => menuModelStateSlice.createMenuCategory(menuModelId,
                menuId,
                {name: data.name, displayOrder: 0}, reztoAuth));
        setCreateDialogOpen(false)
    }


    return (
        <div className="p-5">
            <form onSubmit={handleSubmit(onSub)}>
                <InputField name="name" title="Menykategori" control={control}/>
                <div className="flex flex-row justify-between mt-10">
                    <button type="button" onClick={() => setCreateDialogOpen(false)}
                            className="dropdown-button">Avbryt
                    </button>
                    <button type="submit" className="dropdown-button">Spara</button>
                </div>

            </form>


        </div>
    )
}
