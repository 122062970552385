import {useParams} from "react-router";
import {userReztoStore} from "../state/rootStore";
import {useHistory} from "react-router-dom";
import React from "react";
import {Kiosk} from "../Model/Kiosk";
import './KioskConfigurationPage.css';

export default function KioskConfigurationPage() {

    const {kioskStateSlice} = userReztoStore();
    const {restaurantId, kioskId} = useParams<{ restaurantId: string, kioskId: string }>();
    const kiosk: Kiosk = kioskStateSlice.kiosks.find((r: any) => r.id === kioskId);
    const history = useHistory();
    if (!kiosk) {
        return <></>
    }
    return (
        <>
            <h1 className="text-header m-2 mt-10">{kiosk.name}</h1>
            <hr className="border-primary border-t-2 border-solid"/>
            <div className="flex flex-row justify-center">
                {
                    kiosk.kioskConfigs.map(kioskConfig => {
                        return <div className="flex flex-row justify-between kiosk-configuration-list-item border-2 p-3 m-2 w-1/2">
                            <h1>{kioskConfig.name}</h1>
                            <h1>{kioskConfig.active}</h1>
                        </div>
                    })
                }

            </div>

        </>
    );

}
