import {Report} from "../Model/Report";
import jsPDF from "jspdf";
import {TFunction} from "react-i18next";

export function GenerateReportPdf(t: TFunction<"translation", undefined>, report: Report){
    const doc: any = new jsPDF()


    const pageSize = doc.internal.pageSize
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    const leftPadding = 15;
    const rightPadding = 80;
    const startYCoordinate = 45;
    let rowIncrementer = 5;

    function getRowIncrementer() {
        rowIncrementer += 5;
        return rowIncrementer;
    }

    function getDivider() {
        let divider = "";
        for(let i = 0; i < pageWidth/1.6; i++){
            divider += "-";
        }
        return divider;
    }

    doc.setTextColor(100)
    doc.setFontSize(30)
    doc.text("Z-dagrapport #" + report.zdayReportCounter, pageWidth / 3.5, 20);
    doc.setFontSize(14)
    doc.text(report.displayName + ` (${report.manufacturerId})`, pageWidth / 3.5, 30);



    doc.setFontSize(10)
    doc.setTextColor(100)
    doc.text("REZTO AB", 10, pageHeight - 25);
    doc.text("Org.nummer: 559270-9066", 10, pageHeight - 20);
    doc.text("Adress: FRIGGAGATAN 13 B, 411 01 Göteborg", 10, pageHeight - 15);
    doc.text("©2021 REZTO AB. All rights reserved.", 10, pageHeight - 10);


    doc.setFontSize(12)
    doc.setTextColor(100)


    // OVERVIEW

    doc.text(t("REPORT_OPENED"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.openedAt?.toString())?.split(".")[0].replace("T", " "), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_CLOSED"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.closedAt?.toString())?.split(".")[0].replace("T", " "), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SEQUENCE_NUMBER"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.zdayReportCounter.toString(), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_ID"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.id, pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_RESTAURANT_ID"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.restaurantId, pageWidth - rightPadding, startYCoordinate + rowIncrementer);


    // OVERVIEW
    doc.text(getDivider(), leftPadding, startYCoordinate + getRowIncrementer());

    doc.text(t("REPORT_SALES_INCL_VAT"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalSale.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_EXCL_VAT"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalSaleExclVAT.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_NUMBER_OF_PAYMENTS"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.numberOfPayments.toString(), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_VAT_25"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalMoms25.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_VAT_12"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalMoms12.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_VAT_6"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalMoms6.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_VAT_0"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalMoms0.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);



    // SWISH AND CARD
    doc.text(getDivider(), leftPadding, startYCoordinate + getRowIncrementer());

    doc.text(t("REPORT_SALES_NUMBER_OF_SWISH_PAYMENTS"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.totalSwishPayments.toString(), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_SWISH_PAYMENTS_TOTAL"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalSwishPaymentsAmount.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_NUMBER_OF_CARD_PAYMENTS"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.totalCardPayments.toString(), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_SALES_CARD_PAYMENTS_TOTAL"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalCardPaymentsAmount.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);


    // RETURNS
    doc.text(getDivider(), leftPadding, startYCoordinate + getRowIncrementer());

    doc.text(t("REPORT_RETURN_NUMBER_OF_RETURNS"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.numberOfReturns.toString(), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_RETURN_TOTAL_AMOUNT"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalReturn.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_RETURN_NUMBER_OF_SWISH_RETURNS"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.totalSwishPaymentReturns.toString(), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_RETURN_SWISH_TOTAL"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalSwishReturnAmount.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_RETURN_NUMBER_OF_CARD_RETURNS"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text(report.totalCardPaymentReturns.toString(), pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_RETURN_CARD_TOTAL"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.totalCardReturnAmount.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);


    // GRAND TOTAL
    doc.text(getDivider(), leftPadding, startYCoordinate + getRowIncrementer());

    doc.text(t("REPORT_GRAND_TOTAL"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.grandTotalSale.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_GRAND_TOTAL_EXCL_VAT"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.grandTotalNet.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);

    doc.text(t("REPORT_GRAND_TOTAL_RETURN"), leftPadding, startYCoordinate + getRowIncrementer());
    doc.text((report.grandTotalReturn.amount / 100) + " kr", pageWidth - rightPadding, startYCoordinate + rowIncrementer);








    doc.save('z-dagrapport.pdf')
}