import * as React from 'react';
import FormDialog from "../Modal/FormDialog";

export default function CopyFlowForm({onClick, onDismiss}: { onClick: any, onDismiss: any }) {


    const onSubmit = (data: any) => {
        onClick(data);
        onDismiss();
    }

    return (
        <FormDialog title={"Kopiera flöde"}
                    open={true} setOpen={onDismiss}
        >
            <div className="flex flex-col justify-between h-full">
                <div className="flex flex-row justify-center pt-10">
                    <h1 className="text-4xl">Vill du verkligen kopiera flödet?</h1>
                </div>
                <div className="flex flex-row justify-between pb-20 pr-24 pl-24">
                    <button onClick={() => {onSubmit(false)}} className="dropdown-button text-2xl text-white bg-black">Avbryt</button>
                    <button onClick={() => onSubmit(true)}
                            className="dropdown-button text-2xl text-white bg-black">Kopiera
                    </button>
                </div>
            </div>
        </FormDialog>

    );

}