import {Redirect, Route, Switch} from "react-router-dom";
import {PrivateRoute} from "./PrivateRoute";
import Home from "./Home";
import SignIn from "./SignIn";
import React from "react";
import UserPage from "./Users/UserPage";
import {AdminRoute} from "./AdminRoute";
import UserForm from "./Users/UserForm";

export default function Redirector(){
        return <Switch>
            <Route exact path="/">
                <Redirect to="/home"/>
            </Route>
            <PrivateRoute exact path="/home" component={Home}/>
            <AdminRoute exact path="/users" component={UserPage}/>
            <AdminRoute exact path="/users/form" component={UserForm}/>
            <PrivateRoute exact path="/restaurant-chains/:restaurantChainId" component={Home}/>
            <PrivateRoute path="/restaurants/:restaurantId" component={Home}/>
            <Route path="/signin" component={SignIn}/>
            <Route>
                <Redirect to="/"/>
            </Route>
        </Switch>
}