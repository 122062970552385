import * as React from 'react';
import InputField from "../InputField";
import {useForm} from "react-hook-form";

export default function NameForm({title, previous, onSubmit, setChangeNameDialog}: {
    title: string,
    previous: any, onSubmit: any, setChangeNameDialog: any
}) {

    const {control, handleSubmit, formState: {}} = useForm({
        defaultValues: {
            name: previous
        }
    });

    return (
        <div className="p-5 h-full ">
            <form className="flex flex-col justify-between h-full" onSubmit={handleSubmit(onSubmit)}>
                <InputField name="name" title={title} control={control}/>
                <div className="flex flex-row justify-between mt-10">
                    <button type="button"
                            onClick={() => setChangeNameDialog(false)}
                            className="dropdown-button">Avbryt
                    </button>
                    <button type="submit" className="dropdown-button">Spara</button>
                </div>
            </form>

        </div>
    );
}