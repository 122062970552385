import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import './FormDialog.css';
import {makeStyles} from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog({title, open, setOpen, height = 400, width= 600, children}:
                                       { title: string, open: any, setOpen: any,
                                           height?: any,  width?: number,children: any }) {

    //Set the styles
    const useStyles = makeStyles(() => ({
        paper: { maxWidth: "100%", width: width },
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <Dialog
            classes={{ paper: classes.paper}}
            open={open}
            // TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle style={{fontFamily: 'Grold-light', fontSize: '3rem'}}>{title}</DialogTitle>
            {
                <div style={{height: height, width: width}} className="form-dialog overflow-x-hidden">
                    {children}
                </div>
            }

        </Dialog>
    );
}
