import './App.css';
import { useHistory } from 'react-router-dom';
import "./sign-in.css";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {userReztoStore} from "./state/rootStore";
import {auth} from "./firebase";


function SignIn() {
    const {reztoAuth} = userReztoStore();
    const history = useHistory()
    const { register, handleSubmit, formState: { errors } } = useForm();

    const log = (data: any) => {
        auth.signInWithEmailAndPassword(data.email, data.password).then(result => {
            reztoAuth.login(result);
        }).catch(e => {
            console.log(e)
        })
    }
    useEffect(() => {
        if (reztoAuth.user !== undefined) {
            history.push("/restaurants")
        }

    }, [reztoAuth.user, history])

    return (

        <div className="login-page">
            <div className="login-box shadow-2xl p-10 log-box">
                <div className="flex flex-row justify-center mb-5">
                    <label className="text-mainFont text-3xl">Välkommen!</label>
                </div>
                <form onSubmit={handleSubmit(log)} className="flex flex-col">
                    <input type="text" className="input-normal"
                        placeholder="Email" {...register("email", { required: true })} />
                    {errors.email && errors.email.type === "required" &&
                        <span className="validation-required">An email is required</span>}
                    {errors.email && errors.email.type === "auth-failed" &&
                        <span className="validation-required">{errors.email.message}</span>}
                    <input className="input-normal" type="password"
                        placeholder="Password" {...register("password", { required: true })} />
                    {errors.password && errors.password.type === "required" &&
                        <span className="validation-required">A password is required</span>}
                    <button type={"submit"} className="dropdown-button">Logga in</button>
                </form>
                {reztoAuth.errorMessage &&
                    <h1 className="p-2 text-2xl text-red-500">{reztoAuth.errorMessage}</h1>
                }
            </div>
        </div >
    );
}

export default SignIn;
