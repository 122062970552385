import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Restaurant} from '../Model/RestaurantChainAggregate';
import Logo from '../assets/rezto_logo_black.png';
import './Drawer.css';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import VideoLabelRoundedIcon from '@mui/icons-material/VideoLabelRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import RestaurantDropDownMenu from "../navbar/RestaurantDropDownMenu";
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import {isAdmin} from "../common/Auth";
import {userReztoStore} from "../state/rootStore";

function ListItem({title, path, onSelect, children}: { title: string, path: string, onSelect: any, children: any }) {
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const isActive = window.location.href.includes(path)
    return (
        <Link onClick={() => {
            onSelect()
        }}
              className={"flex flex-row items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300  duration-200 " + (isActive ? " bg-gray-300" : "")}
              to={"/restaurants/" + restaurantId + "/" + path}>
            {children}
            <span className="text-normal ml-2 mt-1.5">{title}</span>
        </Link>
    );
}

export default function ReztoDrawer() {
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const {restaurantChainAggregateState} = userReztoStore();
    const restaurant: Restaurant | undefined = restaurantChainAggregateState.restaurants.find((r: Restaurant) => r.id === restaurantId)
    const [drawerHidden, setDrawerHidden] = useState(false);
    const size = useWindowSize();
    const drawerWidthThreshold = 1300;
    const {reztoAuth} = userReztoStore();
    const [isMobile, setIsMobile] = useState(false);




    useEffect(() => {
        if (size.width < drawerWidthThreshold) {
            setDrawerHidden(true);
            setIsMobile(true);
        } else {
            setDrawerHidden(false);
            setIsMobile(false);
        }

    }, [size])


    if (drawerHidden) {
        return (
            <div onClick={() => setDrawerHidden(false)}>
                <span className="drawer-button">&#9776;</span>
            </div>);
    }

    function onSelect() {
        if (size.width < drawerWidthThreshold) {
            setDrawerHidden(true);
        }
    }


    return (
        <div className="shadow-2xl h-screen fixed drawer-div w-52">
            <div className="flex flex-col items-center h-full text-gray-700">
                <div className="flex flex-row w-full justify-center">
                    <div className="flex flex-col items-center w-full mt-3">
                        <img className="rounded-t-2xl"
                             style={{height: 60, width: 60, objectFit: 'contain'}} src={restaurant?.logo}/>
                        <div className="border-t border-gray-300 pt-2 w-full pl-2 pr-2 mt-2">
                            <RestaurantDropDownMenu/>
                        </div>

                    </div>
                </div>

                <div className="flex flex-col w-full px-2  h-full">

                    <div className="flex flex-col items-center w-full mt-3 border-t border-gray-300 ">
                        <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Startsida"} path={"dashboard"}>
                            <DashboardRoundedIcon fontSize={"medium"}/>
                        </ListItem>
                        <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Statistik"} path={"statistics"}>
                            <BarChartRoundedIcon fontSize={"medium"}/>
                        </ListItem>
                        <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Kvitton"} path={"receipts"}>
                            <ReceiptRoundedIcon fontSize={"medium"}/>
                        </ListItem>
                        <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Rapporter"} path={"reports"}>
                            <FeedRoundedIcon fontSize={"medium"}/>
                        </ListItem>
                    </div>

                    {!isMobile &&
                        <div className="flex flex-col items-center w-full border-t mt-2 border-gray-300 ">
                            <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Produkter"} path={"products"}>
                                <FastfoodRoundedIcon fontSize={"medium"}/>
                            </ListItem>
                            <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Menyer"} path={"menu-models"}>
                                <MenuBookRoundedIcon fontSize={"medium"}/>
                            </ListItem>
                            <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Flöden"} path={"flows"}>
                                <AccountTreeRoundedIcon fontSize={"medium"}/>
                            </ListItem>
                        </div>
                    }

                    {isAdmin(reztoAuth) &&
                    <div className="flex flex-col items-center w-full border-t mt-2 border-gray-300 ">

                            <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Enheter"} path={"kiosks"}>
                                <VideoLabelRoundedIcon fontSize={"medium"}/>
                            </ListItem>

                        <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Bilder"} path={"images"}>
                            <CollectionsRoundedIcon fontSize={"medium"}/>
                        </ListItem>
                        <ListItem onSelect={onSelect} title={drawerHidden ? "" : "Kassörer"} path={"cashiers"}>
                            <PeopleAltRoundedIcon fontSize={"medium"}/>
                        </ListItem>
                    </div>
                    }
                        
                    {(size.width < drawerWidthThreshold && !drawerHidden) &&
                        <div onClick={() => setDrawerHidden(true)}>
                            <span className="drawer-button">&#9776;</span>
                        </div>
                    }
                    <div className="p-5 content-center left-4 absolute bottom-2">

                        <img style={{maxWidth: '120px'}} src={Logo}/>
                    </div>
                </div>

            </div>
        </div>

    )
}

// Hook
export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

