import React from 'react'
import {useForm} from 'react-hook-form';

import {FLOW_TYPE} from '../../common/Constants';
import InputField from '../../common/InputField';
import RadioButtonGroup from '../../common/RadioButtonGroup';
import {assertTokenValidity} from "../../common/ReztoToken";
import {userReztoStore} from "../../state/rootStore";

export default function FlowForm({setCreateDialogOpen}: { setCreateDialogOpen: any }) {
  
    const {selectedRestaurant} = userReztoStore();
    const {reztoAuth, flowStateSlice} = userReztoStore();

    const {register, handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            flowType: FLOW_TYPE['Upsellflöde - Min Order']
        }
    })

    const onSub = (data: any) => {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.createFlow( {
                    restaurantChainId: selectedRestaurant.restaurantChainId,
                    restaurantId: selectedRestaurant.restaurantId,
                    displayTitle: data.name,
                    displayDesc: data.description,
                    flowType: data.flowType,
                    displayOrder: 0,
                    dismissible: true
                }, reztoAuth);
            });

        setCreateDialogOpen(false);

    }

    return (
        <div className="p-5">
            <form onSubmit={handleSubmit(onSub)}>
                <InputField name="name" title="Flödesnamn" control={control}/>
                <InputField name="description" title="Beskrivning" control={control}/>
                <RadioButtonGroup title={'Flöde'} values={FLOW_TYPE} register={register} formControlName={'flowType'}/>
                <div className="flex flex-row justify-between mt-10">
                    <button type="button"
                            onClick={() => setCreateDialogOpen(false)}
                            className="dropdown-button">Avbryt
                    </button>
                    <button type="submit" className="dropdown-button">Spara</button>
                </div>
            </form>

        </div>
    )
}
