import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import './ReztoSwitch.css';
export default function ReztoSwitch({ defaultChecked, callback, width = 60, height = 30}:
     { defaultChecked: boolean, callback: any, width?: number, height?: number}) {
    const [checked, setChecked] = useState(defaultChecked);
    const useStyles = makeStyles({
        tooltip: {
            fontSize: "1em",
        },
    });

    return (
        <div style={{ width: width, height: height}} onClick={() => {
            callback(checked)
            setChecked(!checked)
        }} className="tg-list-item">
            <input onChange={() =>{}}checked={checked} className="tgl tgl-flat" id="cb4" type="checkbox" />
            <label style={{ width: width, height: height}} className="tgl-btn"></label>
        </div>

    )
}
