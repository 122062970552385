import './ActionDropdown.css';
import React, {useState} from "react";
import OutsideClick from "../OutsideClick";
import ReorderRoundedIcon from "@mui/icons-material/ReorderRounded";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import UnpublishedRoundedIcon from '@mui/icons-material/UnpublishedRounded';
export enum ACTION_DROPDOWN_ENUM{
    REORDER,
    ADD,
    EDIT,
    DELETE,
    COPY,
    PUBLISH,
    UNPUBLISH
}

export interface ActionDropdownItem {
    label: string,
    action: any,
    icon?: ACTION_DROPDOWN_ENUM
}

export default function ActionDropdown({actionDropdownItems}:
                                           { actionDropdownItems: ActionDropdownItem[] }) {

    const [show, setShow] = useState(false);
    function getIcon(iconType: ACTION_DROPDOWN_ENUM){
        switch (iconType){
            case ACTION_DROPDOWN_ENUM.REORDER:
                return <ReorderRoundedIcon/>
            case ACTION_DROPDOWN_ENUM.ADD:
                return <AddCircleRoundedIcon/>
            case ACTION_DROPDOWN_ENUM.EDIT:
                return <EditRoundedIcon/>
            case ACTION_DROPDOWN_ENUM.DELETE:
                return <DeleteRoundedIcon/>
            case ACTION_DROPDOWN_ENUM.COPY:
                return <ContentCopyRoundedIcon/>
            case ACTION_DROPDOWN_ENUM.PUBLISH:
                return <CheckCircleRoundedIcon/>
            case ACTION_DROPDOWN_ENUM.UNPUBLISH:
                return <UnpublishedRoundedIcon/>
            default:
                return <ReorderRoundedIcon/>
        }
    }
    return (<>

        <div className="button-div">
            {show &&
                <button className="dropdown-button h-10" onClick={() => setShow(false)}>Stäng</button>

            }
            {show &&
                <OutsideClick clicked={function () {
                    setShow(false)
                }}>
                    <div className="action-dropdown shadow-2xl text-mainFont text-2xl bg">
                        {
                            actionDropdownItems.map((action, index) => {

                                return <div key={action.label + index} className="list-alternative flex flex-row items-center p-1 gap-3">
                                    {getIcon(action.icon || ACTION_DROPDOWN_ENUM.REORDER)}
                                    <p className="text-normal text-2xl mt-2"  onClick={() => {
                                        action.action();
                                        setShow(false)
                                    }
                                    }>{action.label}</p>
                                </div>
                            })
                        }
                    </div>
                </OutsideClick>
            }
            {!show &&
                <button className="dropdown-button h-10" onClick={() => setShow(true)}>Redigera</button>

            }
        </div>


    </>)


}