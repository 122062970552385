import * as React from 'react';
import FormDialog from "../Modal/FormDialog";
import {useForm} from "react-hook-form";
import {Flow} from "../Model/Flow";
import {useTranslation} from "react-i18next";
import {userReztoStore} from "../state/rootStore";

export default function CopyFlowCategoryForm({onClick, onDismiss}: { onClick: any, onDismiss: any }) {
    const {register, handleSubmit} = useForm({});
    const {flowStateSlice} = userReztoStore();
    const flows: Flow[] =flowStateSlice.flows;
    const {t} = useTranslation();

    const onSubmit = (data: any) => {
        onClick(data);
        onDismiss();
    }

    return (
                <FormDialog title={t('COPY_FLOW_CATEGORY')}
                            open={true} setOpen={onDismiss}
                >
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-between h-full w-full">
                        <div className="flex flex-row flex-wrap">
                            {
                                flows.map((flow: Flow) => {
                                    return (
                                        <label key={flow.id} className="p-2 text-2xl text-mainFont">
                                            <input type="radio" className="" value={flow.id}
                                                   {...register('flowId')} />
                                            {flow.displayTitle}
                                        </label>
                                    )
                                })
                            }
                        </div>
                        <div className="flex flex-row justify-between p-5 ">
                            <button type="button"
                                    onClick={onDismiss}
                                    className="dropdown-button">Avbryt
                            </button>
                            <button type="submit" className="dropdown-button">Spara</button>
                        </div>
                    </form>

                </FormDialog>
    );

}