import React from 'react'
import {Order} from "../Model/Order";
import {Kiosk} from "../Model/Kiosk";
import { useTranslation } from 'react-i18next';
import {userReztoStore} from "../state/rootStore";

export default function ReceiptFilter(
    { setFilteredOrders, originalOrders}: { setFilteredOrders: any, originalOrders: Order[] }) {

    const {kioskStateSlice} = userReztoStore();
    const kiosks = kioskStateSlice.kiosks;
    const { t } = useTranslation();

    return (
        <select className="dropdown-button h-10 mt-3" onChange={event => {
            if (event.target.value === "") {
                setFilteredOrders(originalOrders)
            } else {
                setFilteredOrders(
                    originalOrders.filter((order: Order) => order.kioskId === event.target.value))
            }

        }}>
            <option value="">{t('ALL_KIOSKS')}</option>
            {
                kiosks.map((kiosk: Kiosk) => {
                    return <option key={kiosk.id} value={kiosk.id}>{kiosk.name}</option>
                })
            }
        </select>
    )
}
