import React, {useEffect} from "react";

import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {assertTokenValidity} from "../common/ReztoToken";
import ReztoDateRangePicker from "../common/ReztoDateRangePicker";
import ReztoLineChart from "../common/ReztoLineChart";
import {ReztoBarChart} from "../common/Charts/ReztoBarChart";
import {ProductSummary, TagsSummary} from "../Model/ReportSummary";
import ReztoImage from "../common/Image/ReztoImage";
import './Statistics.css';
import {userReztoStore} from "../state/rootStore";

export default function StatisticsPage() {

    const {restaurantChainAggregateState} = userReztoStore();
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const {t} = useTranslation();
    const restaurant = restaurantChainAggregateState.restaurants.find((r: any) => r.id === restaurantId);
  
    const {reztoAuth, statisticStateSlice} = userReztoStore();
    const previousSelectedDateInterval = statisticStateSlice.selectedDateInterval ? statisticStateSlice.selectedDateInterval :
        {fromDate: new Date().toISOString(), toDate: new Date().toISOString()};


    function onDateRangeSelected(value: any) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                statisticStateSlice.fetchIntervalSummary(restaurantId, reztoAuth,
                    value[0]?.toISOString().split("T")[0],
                    value[1]?.toISOString().split("T")[0]);
            });
    }


    useEffect(() => {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                statisticStateSlice.fetchIntervalSummary(restaurantId, reztoAuth,
                    previousSelectedDateInterval.fromDate.split("T")[0],
                    previousSelectedDateInterval.toDate.split("T")[0]);
            });
    }, [restaurant])

    function parseDate(str : any) {
        var mdy = str.split('-');
        return new Date(mdy[0], mdy[1], mdy[2]);
    }

    function datediff(first: any, second: any) {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((second-first)/(1000*60*60*24));
    }

    function daysSelected(first: any, second: any){
        return datediff(parseDate(first), parseDate(second));
    }


    if (!restaurant) {
        return (<></>);
    }
    return (
        <>  
            <div className="statistics-header">
                <div className="flex flex-col justify-top">
                    <h1 className="text-header mb-2 mt-10">{t('STATISTICS').toUpperCase()}</h1>
                    <h1 className="text-normal text-xl mb-2">{t('STATISTICS_PAGE_SUB_HEADER')}</h1>
                </div>
                <div className="flex flex-col justify-between pt-12 pb-3">
                    <ReztoDateRangePicker onChange={onDateRangeSelected}
                                        previousSelectedDateInterval={previousSelectedDateInterval}/>
                </div>
            </div>
            
            <hr className="border-secondary border-t-2 border-solid mt-5"/>
            
            { !statisticStateSlice.intervalReportSummary &&
                <div className="flex flex-col justify-center text-center absolute"
                    style={{left: '40%', top: '50%'}}>
                    <h1 className="text-normal text-2xl align-middle text-mainFont">
                        {t("STATISTICS_NO_RESULT")}</h1>
                </div>
            }

            {statisticStateSlice.intervalReportSummary &&
                
                <div className="shadow-xl p-5 mt-5 flex flex-col">
                    <div className="statistic-card-row pb-10">
                        <div className="statistic-card border-2 rounded-lg p-10 mr-10 mb-5">
                            <h1 className="text-mainFont text-1xl font-bold">{restaurant.franchiseName}</h1>
                            <h1 className="text-mainFont text-3xl font-bold">{restaurant.name}</h1>
                            <h1 className="text-gray-500 mt-1  text-xs font-bold">{previousSelectedDateInterval.fromDate.split("T")[0]} - {previousSelectedDateInterval.toDate.split("T")[0]}</h1>
                            <h1 className="text-gray-500 mt-3  text-xs font-bold">Dagar: {(daysSelected(previousSelectedDateInterval.fromDate.split("T")[0], previousSelectedDateInterval.toDate.split("T")[0])).toString()} st</h1>
                            <h1 className="text-gray-500 mt-1  text-xs font-bold">Öppna dagar: {statisticStateSlice.intervalReportSummary.dayStatistics.length} st</h1>
                        </div>
                        <div className="statistic-card border-2 rounded-lg p-10 mr-10 mb-5">
                            <h1 className="text-mainFont text-1xl font-bold">{t('TOTAL_SALE')}</h1>
                            <h1 className="text-mainFont text-3xl font-bold">{statisticStateSlice.intervalReportSummary.totalSale.amount / 100} kr</h1>
                            <h1 className="text-gray-500 mt-1 align-text-end text-xs font-bold">exkl moms. {statisticStateSlice.intervalReportSummary.totalSaleExVat.amount / 100} kr</h1>
                            <h1 className="text-gray-500 mt-3 text-xs font-bold">Kort: {statisticStateSlice.intervalReportSummary.summaryReport.totalCardPaymentsAmount.amount / 100} kr</h1>
                            <h1 className="text-gray-500 mt-1 text-xs font-bold">Swish: {statisticStateSlice.intervalReportSummary.summaryReport.totalSwishPaymentsAmount.amount / 100} kr</h1>
                            <h1 className="text-gray-500 mt-1 text-xs font-bold">Kontant: {statisticStateSlice.intervalReportSummary.summaryReport.totalCashPaymentsAmount.amount / 100} kr</h1>
                        </div>
                        <div className="statistic-card border-2 rounded-lg p-10 mr-10 mb-5">
                            <h1 className="text-mainFont text-1xl font-bold">{t('TOTAL_UPLSELL')}</h1>
                            <h1 className="text-mainFont text-3xl font-bold">{statisticStateSlice.intervalReportSummary.totalUpsell.amount / 100} kr</h1>
                            <h1 className="text-gray-500 mt-1 text-xs font-bold">exkl moms. {statisticStateSlice.intervalReportSummary.totalUpsellExVat.amount / 100} kr</h1>

                            <h1 className="text-gray-500 mt-3 text-xs font-bold">Snitt per order: {((statisticStateSlice.intervalReportSummary.totalUpsell.amount / 100) / statisticStateSlice.intervalReportSummary.numberOfOrders).toFixed(2)} kr</h1>
                            <h1 className="text-gray-500 mt-1 text-xs font-bold">Procent: {((statisticStateSlice.intervalReportSummary.totalUpsell.amount / 100) / (statisticStateSlice.intervalReportSummary.totalSale.amount / 100) * 100).toFixed(2)} %</h1>
                        </div>
                    </div>
                    <div className="statistic-card-row pb-10">
                        <div className="statistic-card border-2 rounded-lg p-10 mr-10 mb-5">
                            <h1 className="text-mainFont text-1xl font-bold">{t('NUMBER_OF_PAYMENTS')}</h1>
                            <h1 className="text-mainFont text-3xl font-bold">{statisticStateSlice.intervalReportSummary.numberOfOrders} st</h1>
                            <h1 className="text-gray-500 mt-3  text-xs font-bold">Kort: {statisticStateSlice.intervalReportSummary.summaryReport.totalCardPayments} st</h1>
                            <h1 className="text-gray-500 mt-1  text-xs font-bold">Swish: {statisticStateSlice.intervalReportSummary.summaryReport.totalSwishPayments} st</h1>
                            <h1 className="text-gray-500 mt-1  text-xs font-bold">Kontant: {statisticStateSlice.intervalReportSummary.summaryReport.totalCashPayments} st</h1>
                        </div>
                        <div className="statistic-card border-2 rounded-lg p-10 mr-10 mb-5">
                            <h1 className="text-mainFont text-1xl font-bold">{t('NUMBER_OF_ORDERS')}</h1>
                            <h1 className="text-mainFont text-3xl font-bold">{statisticStateSlice.intervalReportSummary.numberOfOrders} st</h1>
                            <h1 className="text-gray-500 mt-3  text-xs underline">Snitt per order</h1>
                            <h1 className="text-gray-500 mt-1  text-xs font-bold">Nota: {((statisticStateSlice.intervalReportSummary.totalSale.amount / 100) / statisticStateSlice.intervalReportSummary.numberOfOrders).toFixed(2)} kr</h1>
                            <h1 className="text-gray-500 mt-1  text-xs font-bold">Produkter: {((statisticStateSlice.intervalReportSummary.numberOfProducts) / statisticStateSlice.intervalReportSummary.numberOfOrders).toFixed(2)} st</h1>
                            <h1 className="text-gray-500 mt-1  text-xs font-bold">Mersälj: {((statisticStateSlice.intervalReportSummary.totalUpsell.amount / 100) / statisticStateSlice.intervalReportSummary.numberOfOrders).toFixed(2)} kr</h1>
                        </div>
                        <div className="statistic-card border-2 rounded-lg p-10 mr-10 mb-5">
                            <h1 className="text-mainFont text-1xl font-bold">{t('NUMBER_OF_PRODUCTS')}</h1>
                            <h1 className="text-mainFont text-3xl font-bold">{statisticStateSlice.intervalReportSummary.numberOfProducts} st</h1>
                            <h1 className="text-gray-500 mt-3  text-xs underline">Top 3 Taggar</h1>
                            {
                                
                                statisticStateSlice.intervalReportSummary.tagsSummaries.sort((a: any, b: any) => {
                                    return a.totalSold < b.totalSold ? 1 : -1;
                                }).map((t: TagsSummary, index: number) => {
                                    if(index > 2){
                                        return (<></>);
                                    }
                                    return (
                                        <h1 key={t.tagName + index} className="text-gray-500 mt-1  text-xs font-bold">#{index + 1} - {t.tagName}  -  {t.totalSold} st</h1>
                                    );

                                })
                            };
                        </div>
                        
                    </div>
                    <div className="mt-0">
                        <p className="text-mainFont text-3xl font-bold pl-3">Omsättning</p>
                        <p className="text-mainFont text-m pl-3 pt-3">Utveckling dag för dag</p>
                    </div>  
                    <ReztoLineChart
                        title="Försäljning per dag"
                        values={statisticStateSlice.intervalReportSummary.dayStatistics.map((x: any) => {
                            return {label: x.date, value: x.totalSale.amount / 100}
                        })}/>

                    
                    <div className="mt-10">
                        <p className="text-mainFont text-3xl font-bold pl-3">Produkter</p>
                        <p className="text-mainFont text-m pl-3 pt-3">Top 10 Omsättning</p>
                    </div>                       
                    <div className="mt-5 pl-12">
                        <div className="flex flex-row pb-3 mb-5 items-center">
                            <div className="flex flex-row justify-between w-full">
                                {/*<div style={{width: 40}}/>*/}
                                <div className="w-1/4">
                                    <p className="text-mainFont font-bold text-xl pl-8">Namn</p>
                                </div>
                                <div className="w-1/4">
                                    <p className="text-mainFont font-bold text-xl pl-12">Antal</p>
                                </div>
                                <div className="w-1/4">
                                    <p className="text-mainFont font-bold text-xl pl-12">Pris</p>
                                </div>
                                <div className="w-1/4">
                                    <p className="text-mainFont font-bold text-xl pl-6">Omsatt</p>
                                </div>
                            </div>
                        </div>
                        {
                            statisticStateSlice.intervalReportSummary.productSummaries.map((p: ProductSummary, index: number) => {
                                if(index > 9){
                                    return (<></>);
                                } 
                                return (
                                    <div key={p.productName + index} className="flex flex-col">
                                        <div key={index} className="flex flex-row mb-2 items-center">
                                            <p className="text-mainFont font-bold text-xl mr-3">#{index + 1}</p>
                                            <ReztoImage imageUrl={p.productImageUrl} height={40} width={40}/>
                                            <div className="flex flex-row justify-between w-full">
                                                <div className="w-1/4">
                                                    <p className="text-mainFont text-m pl-3">{p.productName}</p>
                                                </div>
                                                <div className="w-1/4">
                                                    <p className="text-mainFont text-m">{p.totalSold} st</p>
                                                </div>
                                                <div className="w-1/4">
                                                    <p className="text-mainFont text-m">{p.unitPrice.amount / 100} kr</p>
                                                </div>
                                                <div className="w-1/4">
                                                    <p className="text-mainFont text-m">{p.totalSoldAmount.amount / 100} kr</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="color-gray-500 border-t-1 border-solid mb-2"/>
                                    </div>
                                    
                                );

                            })
                        }
                    </div>
                    <div className="mt-10">
                        <p className="text-mainFont text-3xl font-bold pl-3">Grafer</p>
                        <p className="text-mainFont text-m pl-3 pt-3">Utveckling dag för dag</p>
                    </div>   
                    <div className="flex lg:flex-row sm:flex-col xs:flex-col pt-6">
                        <div className="lg:w-1/2 sm:w-full xs:w-full">
                            <h1 className="text-mainFont text-2xl text-center pb-2">{t('NUMBER_OF_PRODUCTS')}: {statisticStateSlice.intervalReportSummary.numberOfProducts}</h1>
                            <ReztoBarChart title="Antal sålda produkter"
                                           values={statisticStateSlice.intervalReportSummary.dayStatistics.map((x: any) => {
                                               return {label: x.date, value: x.numberOfProductsSold}
                                           })}/>
                        </div>
                        <div className="lg:w-1/2 sm:w-full xs:w-full">
                            <h1 className="text-mainFont text-2xl text-center pb-2">{t('NUMBER_OF_PAYMENTS')}: {statisticStateSlice.intervalReportSummary.numberOfOrders}</h1>
                            <ReztoBarChart title="Antal betalningar"
                                           values={statisticStateSlice.intervalReportSummary.dayStatistics.map((x: any) => {
                                               return {label: x.date, value: x.numberOfPayments}
                                           })}/>
                        </div>
                    </div>

                    <div className="mt-10">
                        <p className="text-mainFont text-3xl font-bold pl-3">Tag Grupper</p>
                        <p className="text-mainFont text-m pl-3 pt-3">Omsättning per grupp (produkt kan förekomma i flera grupper)</p>
                    </div>                       
                    <div className="mt-5 pl-12">
                        <div className="flex flex-row pb-3 mb-5 items-center">
                            <div className="flex flex-row justify-between w-full">
                                {/*<div style={{width: 40}}/>*/}
                                <div className="w-1/5">
                                    <p className="text-mainFont font-bold text-xl">Namn</p>
                                </div>
                                <div className="w-1/5">
                                    <p className="text-mainFont font-bold text-xl">Takeaway</p>
                                </div>
                                <div className="w-1/5">
                                    <p className="text-mainFont font-bold text-xl">Äta här</p>
                                </div>
                                <div className="w-1/5">
                                    <p className="text-mainFont font-bold text-xl">Totalt</p>
                                </div>
                                <div className="w-1/5">
                                    <p className="text-mainFont font-bold text-xl">Omsatt</p>
                                </div>
                            </div>
                        </div>
                        {
                            statisticStateSlice.intervalReportSummary.tagsSummaries.sort((a: any,b: any) => {
                                return a.totalSoldAmount.amount < b.totalSoldAmount.amount ? 1 : -1;
                            }).map((p: TagsSummary, index: number) => {
                                if(index > 9){
                                    return (<></>);
                                } 
                                return (
                                    <div key={p.tagName + " 1" + index} className="flex flex-col">
                                        <div key={index} className="flex flex-row mb-2 items-center">
                                            <div className="flex flex-row justify-between w-full">
                                                <div className="w-1/5">
                                                    <p className="text-mainFont text-m pl-3">{p.tagName}</p>
                                                </div>
                                                <div className="w-1/5">
                                                    <p className="text-mainFont text-m pl-3">{p.totalSoldTakeaway} st</p>
                                                </div>
                                                <div className="w-1/5">
                                                    <p className="text-mainFont text-m">{p.totalSoldEatIn} st</p>
                                                </div>
                                                <div className="w-1/5">
                                                    <p className="text-mainFont text-m">{p.totalSold} st</p>
                                                </div>
                                                <div className="w-1/5">
                                                    <p className="text-mainFont text-m">{p.totalSoldAmount.amount / 100} kr</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="color-gray-500 border-t-1 border-solid mb-2"/>
                                    </div>
                                    
                                );

                            })
                        }
                    </div>
                </div>

            }

        </>
    );
}
