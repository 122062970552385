import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {ReztoLineChartValue} from "../ReztoLineChart";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export interface ReztoBarChartValue {
    label: string;
    value: number;
}


export function ReztoBarChart({title, values}: {title: string, values: ReztoBarChartValue[]}) {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: title,
            },
        },
    };

    const data = {
        labels: values.map(value => value.label),
        datasets: [
            {
                label: 'Antal',
                data: values.map(value => value.value),
                borderColor: 'rgb(0, 0, 0)',
                backgroundColor: 'rgba(0, 0, 0, 1)',
            }
        ],
    };
    return <Bar options={options} data={data} />;
}
