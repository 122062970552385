import React from "react";
import {useTranslation} from "react-i18next";
import {Restaurant, RestaurantChain} from "../Model/RestaurantChainAggregate";
import {Link, useParams} from 'react-router-dom'
import {userReztoStore} from "../state/rootStore";

export default function RestaurantChainPage() {

    const {restaurantChainAggregateState} = userReztoStore();
    const {restaurantChainId} = useParams<{ restaurantChainId: string }>();
    const {t} = useTranslation();
    const restaurantChain: RestaurantChain = restaurantChainAggregateState.restaurantChains.find((r: any) => r.id === restaurantChainId)!;
    const restaurants: Restaurant[] = restaurantChainAggregateState.restaurants.filter((r: Restaurant) => r.restaurantChainId === restaurantChainId);

    if (!restaurantChain) {
        return (<></>);
    }
    return (
        <div className="w-full h-screen flex flex-col justify-center items-center">

            <div className="flex flex-row justify-center border-b-2 ml-10 mr-10"><h1
                className="text-mainFont sm:text-2xl xl:text-4xl lg:text-2xl p-5">{restaurantChain.name}</h1>
            </div>

            <div className="flex flex-col justify-start h-full mt-10">
                <div className="flex flex-row gap-3 flex-wrap justify-center">
                    {restaurants.length > 0 &&
                        restaurants.map(restaurant => {
                            return (
                                <Link key={restaurant.id} to={"/restaurants/" + restaurant.id + "/dashboard"}>
                                    <div className="dropdown-button">
                                        {restaurant.name}
                                    </div>
                                </Link>
                            )
                        })
                    }
                    {restaurants.length === 0 &&
                        <div className="bg-primary flex flex-row justify-center text-2xl border-2 rounded-xl p-2">
                            Restaurangkedjan innehåller ej några restauranger!
                        </div>
                    }
                </div>
            </div>

        </div>
    );
}
