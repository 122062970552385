import React, {useState} from "react";

export default function TagsCheckbox(
    {title, values, register, formControlName}: {
        title: string, values: any, register: any, formControlName: any
    }) {

    const [tags, setTags] = useState(values);
    const [inputValue, setInputValue] = useState(values);
    return (
        <div className="border-2 p-2 mb-2">
            <label className="input-label-normal">{title}</label>
            <div className="flex flex-row justify-between">
                <div className="mt-2">
                    {
                        tags.map((value: any) => {
                            return (
                                <label key={value} className="input-labelinline-flex items-center pr-5">
                                    <input type="checkbox" className="form-radio" name="accountType"
                                           value={value} {...register(formControlName)} />
                                    <span className="ml-2 text-mainFont">{value}</span>
                                </label>
                            )
                        })
                    }
                </div>
                <div className="flex flex-col gap-2">
                    <input type="text" className="text-gray-700 rounded p-1 bg-secondary"
                           onChange={(event) => setInputValue(event.target.value)}/>
                    <button type="button" className="dropdown-button" onClick={() => {
                        if (!tags.includes(inputValue) && (inputValue.trim() !== "") && typeof inputValue === "string") {
                            setTags([...tags, inputValue]);
                        }
                    }}>Lägg till
                    </button>
                </div>
            </div>
        </div>
    );


}