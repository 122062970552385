import {ReztoAuth} from "../Model/AuthModel";
import {StoreSlice} from "./rootStore";
import axios from "axios";
import {GetHeaders} from "./AuthCommon";
import {Flow} from "../Model/Flow";
import {ShowSnackbar} from "./StateFunctions";
import {SNACKBAR_ENUM} from "./Snackbar";

interface FlowState {
    flows: Flow[],
    fetchFlows: (restaurantId: string, reztoAuth: ReztoAuth) => void;
    createFlow: (flow: any, reztoAuth: ReztoAuth) => void;
    editFlow: (flow: any, flowId: string, reztoAuth: ReztoAuth) => void;
    deleteFlow: (flowId: string, reztoAuth: ReztoAuth) => void;
    copyFlow: (flowId: string, reztoAuth: ReztoAuth) => void;
    publishFlow: (flowId: string, reztoAuth: ReztoAuth, published: boolean) => void;
    createFlowCategory: (flowId: string, flowCategory: any, reztoAuth: ReztoAuth) => void;
    deleteFlowCategory: (flowId: string, flowCategoryId: string, reztoAuth: ReztoAuth) => void;
    copyFlowCategory: (flowId: string, flowCategoryId: string, flowCategory: any, reztoAuth: ReztoAuth) => void;
    publishFlowCategory: (flowId: string, flowCategoryId: string, reztoAuth: ReztoAuth, published: boolean) => void;
    createFlowProduct: (flowId: string, flowCategoryId: string, flowProduct: any, reztoAuth: ReztoAuth) => void;
    deleteFlowProduct: (flowId: string, flowCategoryId: string, flowProductId: string, reztoAuth: ReztoAuth) => void;
    publishFlowProduct: (flowId: string, flowCategoryId: string, flowProductId: string, reztoAuth: ReztoAuth, published: boolean) => void;

}

export interface FlowSlice {
    flowStateSlice: FlowState;
}


export const createFlowSlice: StoreSlice<FlowSlice> = (set, get) => ({
    flowStateSlice: {
        flows: [],
        fetchFlows: (restaurantId: string, reztoAuth: ReztoAuth) => {
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/flows/restaurant/' + restaurantId, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createFlow: (flow: any, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows", flow, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.CREATE_FLOW_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: [...prev.flowStateSlice.flows, result.data]
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.CREATE_FLOW_FAILED)
                    console.log(error)
                },
            );
        },
        editFlow: (flow: Flow, flowId: string, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId, flow, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.EDIT_FLOW_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.EDIT_FLOW_FAILED)
                    console.log(error)
                },
            );
        },
        deleteFlow: (flowId: string, reztoAuth: ReztoAuth) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.DELETE_FLOW_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.filter((flow: any) => flow.id !== flowId)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.DELETE_FLOW_FAILED)
                    console.log(error)
                },
            );
        },
        copyFlow: (flowId: string, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/copy", {}, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.COPY_FLOW_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: [...prev.flowStateSlice.flows, result.data]
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.COPY_FLOW_FAILED)
                    console.log(error)
                },
            );
        },
        publishFlow: (flowId: string, reztoAuth: ReztoAuth, published: boolean) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/publish/" + published, {}, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(published ? SNACKBAR_ENUM.PUBLISH_FLOW_SUCCESS : SNACKBAR_ENUM.UN_PUBLISH_FLOW_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.PUBLISH_FLOW_FAILED)
                    console.log(error)
                },
            );
        },
        createFlowCategory: (flowId: string, flowCategory: any, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/flow-categories", flowCategory, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.CREATE_FLOW_CATEGORY_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.CREATE_FLOW_CATEGORY_FAILED)
                    console.log(error)
                },
            );
        },
        deleteFlowCategory: (flowId: string, flowCategoryId: string, reztoAuth: ReztoAuth) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/flow-categories/" + flowCategoryId, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.DELETE_FLOW_CATEGORY_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.DELETE_FLOW_CATEGORY_FAILED)
                    console.log(error)
                },
            );
        },
        copyFlowCategory: (flowId: string, flowCategoryId: string, flowCategory: any, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/flow-categories/" + flowCategoryId + "/copy", flowCategory, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.COPY_FLOW_CATEGORY_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.COPY_FLOW_CATEGORY_FAILED)
                    console.log(error)
                },
            );
        },
        publishFlowCategory: (flowId: string, flowCategoryId: string, reztoAuth: ReztoAuth, published: boolean) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/flow-categories/" + flowCategoryId + "/publish/" + published, {}, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(published ? SNACKBAR_ENUM.PUBLISH_FLOW_CATEGORY_SUCCESS : SNACKBAR_ENUM.UN_PUBLISH_FLOW_CATEGORY_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.PUBLISH_FLOW_CATEGORY_FAILED)
                    console.log(error)
                },
            );
        },
        createFlowProduct: (flowId: string, flowCategoryId: string, flowProduct: any, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/flow-categories/" + flowCategoryId + "/flow-products", flowProduct, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.CREATE_FLOW_PRODUCT_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.CREATE_FLOW_PRODUCT_FAILED)
                    console.log(error)
                },
            );
        },
        deleteFlowProduct: (flowId: string, flowCategoryId: string, flowProductId: string, reztoAuth: ReztoAuth) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/flow-categories/" + flowCategoryId + "/flow-products/" + flowProductId, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(SNACKBAR_ENUM.DELETE_FLOW_PRODUCT_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.DELETE_FLOW_PRODUCT_FAILED)
                    console.log(error)
                },
            );
        },
        publishFlowProduct: (flowId: string, flowCategoryId: string, flowProductId: string, reztoAuth: ReztoAuth, published: boolean) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/flows/" + flowId + "/flow-categories/" +
                flowCategoryId + "/flow-products/" + flowProductId + "/publish/"
                + published, {}, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    ShowSnackbar(published ? SNACKBAR_ENUM.PUBLISH_FLOW_PRODUCT_SUCCESS : SNACKBAR_ENUM.UN_PUBLISH_FLOW_PRODUCT_SUCCESS)
                    set((prev) => ({
                        flowStateSlice: {
                            ...prev.flowStateSlice,
                            flows: prev.flowStateSlice.flows.map((flow: any) => flow.id !== result.data.id ? flow : result.data)
                        }
                    }))
                },
                (error) => {
                    ShowSnackbar(SNACKBAR_ENUM.PUBLISH_FLOW_PRODUCT_FAILED)
                    console.log(error)
                },
            );

        }
    }
});
