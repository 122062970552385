import {StoreSlice} from "./rootStore";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";
import {ReztoEngineImage} from "../Model/ReztoEngineImage";
import {GetHeaders} from "./AuthCommon";

interface ImageState {
    images: ReztoEngineImage[];
    fetchImages: (tags: string[], reztoAuth: ReztoAuth) => void;
    createImage: (reztoAuth: ReztoAuth, tags: string[], url: string) => void;
    deleteImage: (reztoAuth: ReztoAuth, imageId: string) => void;
}

export interface ImageSlice {
    imageStateSlice: ImageState;
}


export const createImageSlice: StoreSlice<ImageSlice> = (set, get) => ({
    imageStateSlice: {
        images: [],
        fetchImages: (tags: string[], reztoAuth: ReztoAuth) => {
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/images?tags=' + tags.join(","), {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        imageStateSlice: {
                            ...prev.imageStateSlice,
                            images: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createImage: (reztoAuth: ReztoAuth, tags: string[], url: string) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + '/images/save',
                {
                    tags: tags,
                    url: url
                }, {
                    headers: GetHeaders(reztoAuth.token)
                }).then(
                (result) => {
                    set((prev) => ({
                        imageStateSlice: {
                            ...prev.imageStateSlice,
                            images: [...prev.imageStateSlice.images, result.data]
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        deleteImage: (reztoAuth: ReztoAuth, imageId: string) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + `/images/${imageId}`, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        imageStateSlice: {
                            ...prev.imageStateSlice,
                            images: [...prev.imageStateSlice.images.filter((image: ReztoEngineImage) => image.id !== imageId)]
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
    }
});
