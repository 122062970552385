import React, {useState} from 'react';

import {FlowProduct} from "../Model/Flow";
import FormDialog from "../Modal/FormDialog";
import FlowProductForm from "./Forms/FlowProductForm";
import ReztoImage from "../common/Image/ReztoImage";
import {assertTokenValidity} from "../common/ReztoToken";
import CustomDeleteForm from "../common/CustomDeleteForm";
import {useTranslation} from 'react-i18next';
import ReztoSwitch from '../common/ReztoSwitch';
import {userReztoStore} from "../state/rootStore";
import {Product} from "../Model/Product";

export default function FlowProductCard({flowProduct, flowId, flowCategoryId}:
                                            { flowProduct: FlowProduct, flowId: string, flowCategoryId: string }) {

  
    const {reztoAuth, productStateSlice, flowStateSlice} = userReztoStore();
    const product = productStateSlice.products.find((product: Product) => product.id === flowProduct.productId);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const {t} = useTranslation();


    function publish(checked: boolean) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.publishFlowProduct(flowId, flowCategoryId, flowProduct.id,
                    reztoAuth, !checked);
            });
    }


    function deleteFlowProduct(confirmed: boolean) {
        if (confirmed) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    flowStateSlice.deleteFlowProduct(flowId, flowCategoryId, flowProduct.id,
                        reztoAuth);
                });
        }
    }

    return (
        <div  className="max-w-sm bg-gray-50 rounded-2xl h-88 shadow-lg w-64 text-2xl
        font-bold transform duration-100 hover:scale-105 m-3 p-3">
            <div onClick={() => {setCreateDialogOpen(true)}} className="menu-product-view flex flex-col justify-between cursor-pointer">
                <div className="flex flex-row justify-center">
                    <ReztoImage
                        height='170px' width='170px'
                        imageUrl={product?.imageUrl}/>
                </div>
                <div className="flex flex-row p-3 justify-center">

                    <div className="font-bold text-gray-700 text-xl
                    overflow-ellipsis overflow-hidden whitespace-nowrap"> {product?.name}</div>
                </div>
            </div>


            <div className="flex flex-row justify-between pl-3 pr-3 pb-3">
                {createDialogOpen &&
                    <FormDialog title={"Redigera flödesprodukt"}
                                open={createDialogOpen} setOpen={setCreateDialogOpen}
                    >
                        <FlowProductForm setCreateDialogOpen={setCreateDialogOpen}
                                         flowId={flowId}
                                         flowCategoryId={flowCategoryId}
                                         flowProduct={flowProduct}
                        />
                    </FormDialog>
                }
            </div>
        </div>
    )
}
