import {StoreSlice} from "./rootStore";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";
import {ReztoEngineImage} from "../Model/ReztoEngineImage";
import {GetHeaders} from "./AuthCommon";
import {User} from "../Model/UserModel";

export interface SnackbarState {
    open: boolean;
    success: boolean;
    message: string;
    setOpen: (message: string, success: boolean) => void;
    setClose: () => void;
}

export interface SnackbarSlice {
    snackbarStateSlice: SnackbarState;
}


export const createSnackbarSlice: StoreSlice<SnackbarSlice> = (set, get) => ({
    snackbarStateSlice: {
        open: false,
        message: "",
        success: true,
        setOpen: (message: string, success: boolean) => {
            set((prev) => (
                {
                    ...prev,
                    snackbarStateSlice: {
                        ...prev.snackbarStateSlice,
                        open: true,
                        message: message,
                        success: success
                    }
                }
            ))
        },
        setClose: () => {
            set((prev) => (
                {
                    ...prev,
                    snackbarStateSlice: {
                        ...prev.snackbarStateSlice,
                        open: false,
                        message: ''
                    }
                }
            ))
        }
    }
});


export enum SNACKBAR_ENUM{


    // MENU

    CREATE_MENU_MODEL_SUCCESS = "Menymodellen skapad",
    CREATE_MENU_MODEL_FAILED = "Menymodellen kunde inte skapas",
    EDIT_MENU_MODEL_SUCCESS = "Menymodellen uppdaterad",
    EDIT_MENU_MODEL_FAILED = "Menymodellen kunde inte uppdateras",
    DELETE_MENU_MODEL_SUCCESS = "Menymodellen raderades",
    DELETE_MENU_MODEL_FAILED = "Menymodellen kunde inte raderas",
    PUBLISH_MENU_MODEL_SUCCESS = "Menymodellen publicerad",
    UN_PUBLISH_MENU_MODEL_SUCCESS = "Menymodellen avpublicerad",
    PUBLISH_MENU_MODEL_FAILED = "Menymodellen kunde inte publiceraD",

    CREATE_MENU_SUCCESS = "Menyn skapad",
    CREATE_MENU_FAILED = "Menyn kunde inte skapas",
    DELETE_MENU_SUCCESS = "Menyn borttagen",
    DELETE_MENU_FAILED = "Menyn kunde inte tas bort",
    PUBLISH_MENU_SUCCESS = "Menyn publicerad",
    UN_PUBLISH_MENU_SUCCESS = "Menyn avpublicerad",
    PUBLISH_MENU_FAILED = "Menyn kunde inte publiceras",
    COPY_MENU_SUCCESS = "Menyn kopierad",
    COPY_MENU_FAILED = "Menyn kunde inte kopieras",

    PUBLISH_MENU_CATEGORY_SUCCESS = "Menykategorin publicerad",
    UN_PUBLISH_MENU_CATEGORY_SUCCESS = "Menykategorin avpublicerad",
    PUBLISH_MENU_CATEGORY_FAILED = "Menykategorin kunde inte publiceras",
    CREATE_MENU_CATEGORY_SUCCESS = "Menykategorin skapad",
    CREATE_MENU_CATEGORY_FAILED = "Menykategorin kunde inte skapas",
    DELETE_MENU_CATEGORY_SUCCESS = "Menykategorin raderad",
    DELETE_MENU_CATEGORY_FAILED = "Menykategorin kunde inte raderas",


    CREATE_MENU_PRODUCT_SUCCESS = "Menyprodukten skapad",
    CREATE_MENU_PRODUCT_FAILED = "Menyprodukten kunde inte skapas",
    DELETE_MENU_PRODUCT_SUCCESS = "Menyprodukten raderad",
    DELETE_MENU_PRODUCT_FAILED = "Menyprodukten kunde inte raderas",
    PUBLISH_MENU_PRODUCT_SUCCESS = "Menyprodukten publicerad",
    UN_PUBLISH_MENU_PRODUCT_SUCCESS = "Menyprodukten avpublicerad",
    PUBLISH_MENU_PRODUCT_FAILED = "Menyprodukten kunde inte publiceras",


    // FLOW
    CREATE_FLOW_SUCCESS = "Flöde skapat",
    CREATE_FLOW_FAILED = "Flöde kunde inte skapas",
    EDIT_FLOW_SUCCESS = "Flöde uppdaterat",
    EDIT_FLOW_FAILED = "Flöde kunde inte uppdateras",
    DELETE_FLOW_SUCCESS = "Flöde raderat",
    DELETE_FLOW_FAILED = "Flöde kunde raderas",
    COPY_FLOW_SUCCESS = "Flöde kopierat",
    COPY_FLOW_FAILED = "Flöde kunde inte kopieras",
    PUBLISH_FLOW_SUCCESS = "Flöde publicerat",
    UN_PUBLISH_FLOW_SUCCESS = "Flöde avpublicerat",
    PUBLISH_FLOW_FAILED = "Flöde kunde inte publiceras",


    CREATE_FLOW_CATEGORY_SUCCESS = "Flödeskategori skapad",
    CREATE_FLOW_CATEGORY_FAILED = "Flödeskategori kunde inte skapas",
    DELETE_FLOW_CATEGORY_SUCCESS = "Flödeskategori raderad",
    DELETE_FLOW_CATEGORY_FAILED = "Flödeskategori kunde inte raderas",
    COPY_FLOW_CATEGORY_SUCCESS = "Flödeskategori kopierad",
    COPY_FLOW_CATEGORY_FAILED = "Flödeskategori kunde inte kopieras",
    PUBLISH_FLOW_CATEGORY_SUCCESS = "Flödeskategori publicerad",
    UN_PUBLISH_FLOW_CATEGORY_SUCCESS = "Flödeskategori avpublicerad",
    PUBLISH_FLOW_CATEGORY_FAILED = "Flödeskategori kunde inte publiceras",


    CREATE_FLOW_PRODUCT_SUCCESS = "Flödesprodukt skapad",
    CREATE_FLOW_PRODUCT_FAILED = "Flödesprodukt kunde inte skapas",
    DELETE_FLOW_PRODUCT_SUCCESS = "Flödesprodukt raderad",
    DELETE_FLOW_PRODUCT_FAILED = "Flödesprodukt kunde inte raderas",
    PUBLISH_FLOW_PRODUCT_SUCCESS = "Flödesprodukt publicerad",
    UN_PUBLISH_FLOW_PRODUCT_SUCCESS = "Flödesprodukt avpublicerad",
    PUBLISH_FLOW_PRODUCT_FAILED = "Flödesprodukt kunde inte avpubliceras",
}
