import './App.css';
import {Redirect, Route} from 'react-router-dom';
import React from 'react';
import {userReztoStore} from "./state/rootStore";

export function PrivateRoute({component: Component, ...rest} : {component: any, rest: any}) {
    const {reztoAuth} = userReztoStore();

    return (
        <Route
            {...rest}
            render={props => {
            return reztoAuth.email ? <Component {...props}/> : <Redirect to="/signin"/>
        }}
        >
        </Route>
    );
}

