import parseJwt from "./JwtParser";
import {auth} from "../firebase";
import {ReztoAuthState} from "../state/AuthSlice";

export async function assertTokenValidity(reztoAuthState: ReztoAuthState): Promise<string> {
    if (reztoAuthState.reztoJwt && Date.now() >= reztoAuthState.reztoJwt.exp * 1000) {
        console.log("Token invalid, updating token")
        let token = await auth.currentUser?.getIdToken(true);
        if (token) {
            reztoAuthState.setJwt({reztoJwt: parseJwt(token), token: token})
            return token;
        }
        return reztoAuthState.token;

    } else {
        return reztoAuthState.token
    }
}