import {StoreSlice} from "./rootStore";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";
import {ReztoEngineImage} from "../Model/ReztoEngineImage";
import {GetHeaders} from "./AuthCommon";
import {User} from "../Model/UserModel";

export interface UserState {
    users: User[];
    fetchUsers: (reztoAuth: ReztoAuth) => void;
    createUser: (user: any, reztoAuth: ReztoAuth, history: any) => void;
}

export interface UserSlice {
    userStateSlice: UserState;
}


export const createUserSlice: StoreSlice<UserSlice> = (set, get) => ({
    userStateSlice: {
        users: [],
        fetchUsers: ( reztoAuth: ReztoAuth) => {
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/users', {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        userStateSlice: {
                            ...prev.userStateSlice,
                            users: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createUser: (user: any,  reztoAuth: ReztoAuth, history: any) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + '/users/register', user,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        userStateSlice: {
                            ...prev.userStateSlice,
                            users: [...prev.userStateSlice.users, {...user, uid: result.data.uid}]
                        }
                    }));
                   history.push("/users");
                },
                (error) => {
                    console.log("error")
                },
            );
        }
    }
});
