import {StoreSlice} from "./rootStore";
import axios from "axios";
import {Product} from "../Model/Product";
import {ReztoAuth} from "../Model/AuthModel";
import {GetHeaders} from "./AuthCommon";

export interface ProductState {
    products: Product[],
    fetchProducts: (restaurantId: string, reztoAuth: ReztoAuth) => void;
    createProduct: (product: Product, reztoAuth: ReztoAuth, history: any, restaurantId: string) => void;
    updateProduct: (product: Product, reztoAuth: ReztoAuth, history: any, restaurantId: string, productId: string) => void;
    copyProduct: (product: any, reztoAuth: ReztoAuth, history: any, restaurantId: string) => void;
    publishProduct: (productId: string, reztoAuth: ReztoAuth, published: boolean) => void;
    deleteProduct: (productId: string, reztoAuth: ReztoAuth, history: any, restaurantId: string) => void;
}

interface ProductSlice {
    productStateSlice: ProductState;
}

export const createProductSlice: StoreSlice<ProductSlice> = (set, get) => ({
    productStateSlice: {
        products: [],
        fetchProducts: (restaurantId, reztoAuth) => {
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/products/restaurant/' + restaurantId + '/true', {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        productStateSlice: {
                            ...prev.productStateSlice,
                            products: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createProduct: (product: Product, reztoAuth: ReztoAuth, history: any, restaurantId: string) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/products", product, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        productStateSlice: {
                            ...prev.productStateSlice,
                            products: [...prev.productStateSlice.products, result.data]
                        }
                    }));
                    history.push("/restaurants/" + restaurantId + "/products");
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        updateProduct: (product: Product, reztoAuth: ReztoAuth, history: any, restaurantId: string, productId: string) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/products/" + productId, product,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        productStateSlice: {
                            ...prev.productStateSlice,
                            products: prev.productStateSlice.products.map( (product: Product) => product.id === productId ? result.data : product)
                        }
                    }));
                    history.push("/restaurants/" + restaurantId + "/products");
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        copyProduct: (product: any, reztoAuth: ReztoAuth, history: any, restaurantId: string) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/products/" + product.productId + "/copy", product,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        productStateSlice: {
                            ...prev.productStateSlice,
                            products: [...prev.productStateSlice.products, result.data]
                        }
                    }));
                    history.push("/restaurants/" + restaurantId + "/products");
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        publishProduct: (productId: string, reztoAuth: ReztoAuth, published: boolean) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/products/" + productId + "/publish/" + published,{},{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        productStateSlice: {
                            ...prev.productStateSlice,
                            products: prev.productStateSlice.products.map( (product: Product) => product.id === productId ? {...product, published: published} : product)
                        }
                    }));
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        deleteProduct: (productId: string, reztoAuth: ReztoAuth, history, restaurantId) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + '/products/' + productId,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        productStateSlice: {
                            ...prev.productStateSlice,
                            products: prev.productStateSlice.products.filter((product: Product) => product.id !== productId)
                        }
                    }));
                    history.push("/restaurants/" + restaurantId + "/products");
                },
                (error) => {
                    console.log("error")
                },
            );
        },
    },

});
