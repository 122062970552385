import React from "react";
import './navbar.css';
import ProfileNavbar from "./ProfileNavbar";

function Navbar() {
    return (
        <div className="flex flex-row justify-between fixed top-0 z-50 min-w-full">
            <div>
            </div>
            <div className="max-w-full">
                <div>
                    <div className="flex flex-row p-2">
                        <ProfileNavbar/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;
