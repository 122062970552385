import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Kiosk} from "../Model/Kiosk";
import {Report} from "../Model/Report";
import {useTranslation} from 'react-i18next';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import {GenerateReportPdf} from "./ReportPdfGenerator";
import {userReztoStore} from "../state/rootStore";

function ReportCard({reportId}: { reportId: string }) {
    const {reportStateSlice, kioskStateSlice}  = userReztoStore();
    const kiosks = kioskStateSlice.kiosks;
    const report = reportStateSlice.reports.find((report: Report) => report.id === reportId);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const {t} = useTranslation();
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    if (!report) {
        return (<></>)
    }


    const vatRate = getVatRate(report);
    const vatRateReturns = getVatRateReturns(report);



    return (
        <div className="border-2 rounded-b rounded-t">
            <Accordion style={{ backgroundColor: 'white' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{width: '33%', flexShrink: 0, fontWeight: "bold"}}>
                        {(report.openedAt?.toString())?.split(".")[0].replace("T", " ")} - {(report.closedAt?.toString())?.split(".")[0].replace("T", " ")}
                    </Typography>
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            fontWeight: "bold"
                        }}>{kiosks.find((kiosk: Kiosk) => kiosk.id === report.kioskId)?.name}
                    </Typography>
                    <Typography sx={{color: 'text.secondary', paddingLeft: '5px', fontWeight: "bold"}}>|
                        {t('SUM')}: <span className="font-bold">{report.totalSale.amount / 100}</span> kr</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="flex flex-col ">
                        <div className="flex flex-row justify-evenly border-b-2">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('NUMBER_OF_PAYMENTS')}</h1>
                                <h1>{report.numberOfPayments}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL_SALE')}</h1>
                                <h1>{report.totalSale.amount / 100} kr</h1>
                            </div>
                        </div>

                        <div className="flex flex-row justify-evenly border-b-2">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('NUMBER_OF_REFUNDS')}</h1>
                                <h1>{report.numberOfReturns}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL_REFUND')}</h1>
                                <h1>{report.totalReturn.amount / 100} kr</h1>
                            </div>
                        </div>

                        {/* Betalningar kort */}
                        <div className="flex flex-row justify-evenly border-b-2">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('NUMBER_OF_CARD_PAYMENTS')}</h1>
                                <h1>{report.totalCardPayments}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL')}</h1>
                                <h1>{report.totalCardPaymentsAmount.amount / 100} kr</h1>
                            </div>
                        </div>

                        {/* Returer kort */}
                        <div className="flex flex-row justify-evenly border-b-2">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('NUMBER_OF_CARD_PAYMENTS_REFUNDS')}</h1>
                                <h1>{report.totalCardPaymentReturns}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL')}</h1>
                                <h1>{report.totalCardReturnAmount.amount / 100} kr</h1>
                            </div>
                        </div>

                        {/* Betalningar swish */}
                        <div className="flex flex-row justify-evenly border-b-2">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('NUMBER_OF_SWISH_PAYMENTS')}</h1>
                                <h1>{report.totalSwishPayments}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL')}</h1>
                                <h1>{report.totalSwishPaymentsAmount.amount / 100} kr</h1>
                            </div>
                        </div>

                        {/* Returer swish */}
                        <div className="flex flex-row justify-evenly border-b-2 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('NUMBER_OF_SWISH_PAYMENTS_REFUNDS')}</h1>
                                <h1>{report.totalSwishPaymentReturns}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL')}</h1>
                                <h1>{report.totalSwishReturnAmount.amount / 100} kr</h1>
                            </div>
                        </div>

                        {/* Total färsäljning exkl moms*/}
                        <div className="flex flex-row justify-evenly pt-5 pb-5 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1 className="text-2xl">{t('TOTAL_EX_MOMS')}: <span
                                    className="font-bold">{report.totalSaleExclVAT.amount / 100}</span> kr</h1>
                            </div>
                        </div>

                        {/* Momssats */}
                        <div className="flex flex-row justify-evenly border-b-2 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('VAT_RATE')}</h1>
                                <h1>{vatRate.label}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL')}</h1>
                                <h1>{vatRate.amount} kr</h1>
                            </div>
                        </div>

                        {/* Momssats retur */}
                        <div className="flex flex-row justify-evenly border-b-2 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('VAT_RATE')} {t('RETURNS')}</h1>
                                <h1>{vatRateReturns.label}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL')}</h1>
                                <h1>{vatRateReturns.amount} kr</h1>
                            </div>
                        </div>


                        {/* Totalt moms */}
                        <div className="flex flex-row justify-evenly pt-5 pb-5 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1 className="text-2xl">{t('TOTAL_VAT')}: <span
                                    className="font-bold">{report.totalMoms.amount / 100}</span> kr</h1>
                            </div>
                        </div>

                        {/* Netto exkl moms */}
                        <div className="flex flex-row justify-evenly border-b-2 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('NET')} {t('EXCL_VAT')}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{report.totalNet.amount / 100} kr</h1>
                            </div>
                        </div>

                        {/* Netto inkl moms */}
                        <div className="flex flex-row justify-evenly border-b-2 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('NET')} {t('INCL_VAT')}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{report.totalNettoInklVAT.amount / 100} kr</h1>
                            </div>
                        </div>

                        {/* Total sale */}
                        <div className="flex flex-row justify-evenly border-b-2 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL_SALE')}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{report.totalSale.amount / 100} kr</h1>
                            </div>
                        </div>


                        {/* Total netto */}
                        <div className="flex flex-row justify-evenly pt-5 pb-5 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1 className="text-2xl">{t('TOTAL_NETTO')}: <span
                                    className="font-bold">{report.totalNet.amount / 100}</span> kr</h1>
                            </div>
                        </div>

                        <SaveAltRoundedIcon style={{fontSize: 30,cursor: 'pointer'}} onClick={() => {
                            GenerateReportPdf(t, report);
                        }}/>


                        {/* Totalt netto */}
                        {/* <div className="flex flex-row justify-evenly border-b-2 ">
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL_VAT')}</h1>
                                <h1>{vatRateReturns.label}</h1>
                            </div>
                            <div className="flex flex-col w-1/2 items-center">
                                <h1>{t('TOTAL_VAT')}</h1>
                                <h1>{report.totalMoms.amount / 100} kr</h1>
                            </div>
                        </div> */}

                    </div>
                </AccordionDetails>
            </Accordion>
         </div>
    );
}

export default ReportCard;

function getVatRate(report: Report) {

    if (report.totalMoms25 && report.totalMoms25.amount > 0) return {
        label: "25%",
        amount: report.totalMoms25.amount / 100
    };
    if (report.totalMoms12 && report.totalMoms12.amount > 0) return {
        label: "12%",
        amount: report.totalMoms12.amount / 100
    };
    if (report.totalMoms6 && report.totalMoms6.amount > 0) return {label: "6%", amount: report.totalMoms6.amount / 100};
    if (report.totalMoms0 && report.totalMoms0.amount > 0) return {label: "0%", amount: report.totalMoms0.amount / 100};
    return {label: "0%", amount: 0}
}

function getVatRateReturns(report: Report) {

    if (report.totalReturnMoms25 && report.totalReturnMoms25.amount !== 0) return {
        label: "25%",
        amount: report.totalReturnMoms25.amount / 100
    };
    if (report.totalReturnMoms12 && report.totalReturnMoms12.amount !== 0) return {
        label: "12%",
        amount: report.totalReturnMoms12.amount / 100
    };
    if (report.totalReturnMoms6 && report.totalReturnMoms6.amount !== 0) return {
        label: "6%",
        amount: report.totalReturnMoms6.amount / 100
    };
    if (report.totalReturnMoms0 && report.totalReturnMoms0.amount !== 0) return {
        label: "0%",
        amount: report.totalReturnMoms0.amount / 100
    };
    return {label: "0%", amount: 0}
}



