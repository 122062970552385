import React from 'react'
import {useForm} from 'react-hook-form';

import {PRICE_CATEGORY} from "../common/Constants";
import {assertTokenValidity} from "../common/ReztoToken";
import InputField from "../common/InputField";
import CheckBoxNormal from "../product/CheckboxNormal";
import {userReztoStore} from "../state/rootStore";

export default function KioskForm({setCreateDialogOpen}: { setCreateDialogOpen: any }) {
  
    const {selectedRestaurant} = userReztoStore();
    const {reztoAuth, kioskStateSlice} = userReztoStore();

    const {register, handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            priceCategory: PRICE_CATEGORY['Äta här']
        }
    })


    const onSub = (data: any) => {

        assertTokenValidity(reztoAuth)
            .then((_) => {
                kioskStateSlice.createKiosk(selectedRestaurant.restaurantId,
                    reztoAuth,
                    {
                        "name": data.name,
                        "restaurantId": selectedRestaurant.restaurantId,
                        "desc": data.description,
                        "priceCategories": data.priceCategory === false ? [] :
                            typeof data.priceCategory === 'string' ? [data.priceCategory] : data.priceCategory,
                        "gps": {
                            "longitude": null,
                            "latitude": null,
                            "accuracyMeters": null
                        },
                        "whatThreeWords": data.wtw
                    });
            });

        setCreateDialogOpen(false);

    }

    return (
        <div className="p-5">
            <form onSubmit={handleSubmit(onSub)}>
                <InputField name="name" title="Enhetens namn" control={control}/>
                <InputField name="description" title="Beskrivning" control={control}/>
                <InputField name="wtw" title="What three words" control={control}/>
                <CheckBoxNormal
                    title={'Priskategori'}
                    register={register}
                    values={PRICE_CATEGORY}
                    formControlName={'priceCategory'}
                />
                <div className="flex flex-row justify-between mt-10">
                    <button type="button"
                            onClick={() => setCreateDialogOpen(false)}
                            className="dropdown-button">Avbryt
                    </button>
                    <button type="submit" className="dropdown-button">Spara</button>
                </div>
            </form>

        </div>
    )
}
