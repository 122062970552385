import * as React from 'react';
import FormDialog from "../Modal/FormDialog";
import ReorderComponent from "./ReorderComponent";

export default function ReorderForm({title, onClick, onDismiss, reorderItems, setReorderItems}
                                        : { title: any, onClick: any, onDismiss: any, reorderItems: any, setReorderItems: any }) {
    const onSubmit = () => {
        onClick();
        onDismiss();
    }

    return (
        <FormDialog title={title}
                    open={true} setOpen={onDismiss}
        >
            <ReorderComponent state={reorderItems} setState={setReorderItems}/>
            <div className="flex flex-row justify-between p-3 sticky bottom-0 w-full">
                <button className="dropdown-button" onClick={() => onDismiss(false)}>Avbryt
                </button>
                <button className="dropdown-button" onClick={() => {
                    onSubmit()
                }
                }>Spara
                </button>
            </div>
        </FormDialog>);

}