import {StoreSlice} from "./rootStore";
import { ReztoJwt } from "../Model/AuthModel";
import firebase from "firebase";

export interface IReztoAuthSlice{
    reztoAuth: ReztoAuthState
}

export interface ReztoAuthState {
    reztoJwt?: ReztoJwt;
    user?: firebase.User;
    email?: string;
    token: string;
    errorMessage?: string;
    setJwt: (token: {reztoJwt: ReztoJwt, token: string}) => void;
    login: (user: any) => void;
    logout: () => void;
}

export const createAuthSlice: StoreSlice<IReztoAuthSlice> = (set, get) => ({
    reztoAuth: {
        reztoJwt: undefined,
        user: undefined,
        email: undefined,
        token: "",
        errorMessage: undefined,
        setJwt: (token) => {
            set((state) => ({
                ...state,
                reztoAuth: {
                    ...state.reztoAuth,
                    reztoJwt: token.reztoJwt,
                    token: token.token
                }

            }))
        },
        login: (user) => {
            set((state) => ({
                ...state,
                reztoAuth: {
                    ...state.reztoAuth,
                    email: user.email,
                    token: user.Aa,
                    user: user
                }

            }))
        },
        logout: () => {
            set((state) => ({
                ...state,
                reztoAuth: {
                    ...state.reztoAuth,
                    reztoJwt: undefined,
                    user: undefined,
                    email: undefined,
                    token: "",
                    errorMessage: undefined,
                }

            }))
        },
    },
});
