import React from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export interface ReztoLineChartValue {
    label: string;
    value: number;
}


export default function ReztoLineChart({title, values}: {title: string, values: ReztoLineChartValue[]}) {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: title,
            },
        },
    };


    const data = {
        labels: values.map(value => value.label),
        datasets: [
            {
                label: 'Kronor',
                data: values.map(value => value.value),
                borderColor: 'rgb(0, 0, 0)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
        ],
    };


    return <Line options={options} data={data}/>
}
