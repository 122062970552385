import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {DateInterval} from "../Model/DateInterval";
import { DateRangePicker } from '@mantine/dates'
import 'dayjs/locale/sv';
export default function ReztoDateRangePicker({onChange, previousSelectedDateInterval}:
                                                 { onChange: any, previousSelectedDateInterval: DateInterval }) {

    const [value, setValue] = React.useState<[Date | null, Date | null]>([new Date(previousSelectedDateInterval.fromDate),
        new Date(previousSelectedDateInterval.toDate)]);

    function onDateButtonClicked(days: number) {
        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - days)
        let date = new Date();
        setValue([fromDate,date]);
        onChange([fromDate,date]);
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-start">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        locale="sv"
                        style={{width: '80%', zIndex: 0}}
                        placeholder="Pick dates range"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                            if (newValue[0] && newValue[1] && !isNaN(newValue[0]?.getDate()) && !isNaN(newValue[1]?.getDate())) {
                                onChange(newValue);
                            }
                        }}
                    />
                </LocalizationProvider>
            </div>

            <div className="flex lg:flex-row sm:flex-col xs:flex-col justify-end pt-4 gap-1">
                <button className="dropdown-button mr-2" onClick={() => onDateButtonClicked(7)}>7-dagar</button>
                <button className="dropdown-button mr-2" onClick={() => onDateButtonClicked(30)}>30-dagar</button>
                <button className="dropdown-button" onClick={() => onDateButtonClicked(90)}>90-dagar</button>
            </div>
        </div>
    );
}
