import React from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import DayStatistics from "./DayStatistics";
import {userReztoStore} from "../state/rootStore";

export default function Dashboard() {

   const {restaurantChainAggregateState} = userReztoStore();
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const {t} = useTranslation();
    const restaurant = restaurantChainAggregateState.restaurants.find((r: any) => r.id === restaurantId);

    if (restaurant) {

        return (
            <>
                <h1 className="text-header mb-2 mt-10 font-bold">{t('STATISTICS_OF_THE_DAY').toUpperCase()}</h1>
                <h1 className="text-normal text-xl mt-2">{t('DASHBOARD_DAY_STATISTICS')}</h1>
                <DayStatistics/>
            </>
        );
    } else {
        return (
            <></>
        )
    }
}
