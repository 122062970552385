import {StoreSlice} from "./rootStore";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";
import {Kiosk} from "../Model/Kiosk";

interface KioskState {
    kiosks: Kiosk[];
    fetchKiosks: (restaurantId: string, restoAuth: ReztoAuth) => void;
    createKiosk: (restaurantId: string, reztoAuth: ReztoAuth, kiosk: any) => void;
}

export interface KioskSlice {
    kioskStateSlice: KioskState;
}


export const createKioskSlice: StoreSlice<KioskSlice> = (set, get) => ({
    kioskStateSlice: {
        kiosks: [],
        fetchKiosks: (restaurantId, reztoAuth) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/kiosks/' + restaurantId + "/restaurant", {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        kioskStateSlice: {
                            ...prev.kioskStateSlice,
                            kiosks: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createKiosk: (restaurantId: string, reztoAuth: ReztoAuth, kiosk: any) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + '/kiosks', kiosk,{
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        kioskStateSlice: {
                            ...prev.kioskStateSlice,
                            kiosks: [...prev.kioskStateSlice.kiosks, result.data]
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
    }
});
