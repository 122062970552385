import React, {useEffect, useState} from "react";
import LazyLoad from "react-lazyload";

import {useParams} from "react-router-dom";
import ReztoDateRangePicker from "../common/ReztoDateRangePicker";
import {assertTokenValidity} from "../common/ReztoToken";
import {Report} from "../Model/Report";
import ReportCard from "./ReportCard";
import ReportFilter from "./ReportFilter";
import {useTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import {useWindowSize} from "../drawer/ReztoDrawer";
import './ReportList.css'
import {userReztoStore} from "../state/rootStore";

function ReportList() {
    const {reztoAuth, reportStateSlice} = userReztoStore();
    const originalReports: Report[] = reportStateSlice.reports;
    const [filteredReports, setReports] = useState(originalReports);
  
    const {restaurantId} = useParams<{ restaurantId: string }>();

    const previousSelectedDateInterval = reportStateSlice.selectedDateInterval ? reportStateSlice.selectedDateInterval :
        {fromDate: new Date().toISOString(), toDate: new Date().toISOString()};
    const windowSize = useWindowSize();
    const [page, setPage] = useState(1);

    const {t} = useTranslation();

    function onDateRangeSelected(value: any) {
        fetchFirstSetOfReports(value[0]?.toISOString().split("T")[0],
            value[1]?.toISOString().split("T")[0])
    }

    useEffect(() => {
        fetchFirstSetOfReports(previousSelectedDateInterval.fromDate.split("T")[0],
            previousSelectedDateInterval.toDate.split("T")[0])
    }, [])

    useEffect(() => {
        setReports(originalReports.filter((report: Report) => !report.active))
    }, [originalReports])


    function fetchFirstSetOfReports(from: string, to: string) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                reportStateSlice.fetchIntervalPaginationReports(restaurantId, reztoAuth,
                    from, to,1, Math.ceil(windowSize.height / 50))
            });
    }

    function fetchMore() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                reportStateSlice.fetchIntervalPaginationReportsPagination(restaurantId, reztoAuth,
                    previousSelectedDateInterval.fromDate.split("T")[0],
                    previousSelectedDateInterval.toDate.split("T")[0], page + 1, 14)
            });
        setPage(page + 1)
    }

    return (
        <>

            <div className="report-list-header mb-3">
                <div className="flex flex-col justify-top">
                    <h1 className="text-header mb-2 mt-10">{t('REPORTS').toUpperCase()}</h1>
                    <h1 className="text-normal text-xl mb-2">{t('REPORTS_SUB_HEADER')}</h1>
                    <div className="w-2">
                        <ReportFilter setFilteredReports={setReports} originalReports={originalReports}/>
                    </div>
                </div>
                <div className="flex flex-col justify-between pt-12 pb-3">
                    <ReztoDateRangePicker onChange={onDateRangeSelected}
                                          previousSelectedDateInterval={previousSelectedDateInterval}/>
                </div>
            </div>





            {filteredReports.length === 0 &&
                <div className="flex flex-col justify-center text-center absolute" style={{left: '30%', top: '50%'}}>
                    <h1 className="text-normal text-2xl align-middle">
                        {t("NO_REPORTS_FOUND")}</h1>
                </div>

            }

            {filteredReports.length > 0 &&
                <>
                    <InfiniteScroll
                        dataLength={filteredReports.length}
                        next={fetchMore}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                    >
                        {filteredReports.map((report, index) => (

                            <div className="pb-2" key={index}>
                                <LazyLoad height={50}>
                                    <ReportCard reportId={report.id}/>
                                </LazyLoad>
                            </div>
                        ))}
                    </InfiniteScroll>

                </>
            }
        </>
    );
}

export default ReportList;