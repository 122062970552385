import './App.css';
import {Redirect, Route} from 'react-router-dom';
import React from 'react';
import {userReztoStore} from "./state/rootStore";
import {ReztoAuthState} from "./state/AuthSlice";
import {isAdmin} from "./common/Auth";

export function AdminRoute({component: Component, ...rest} : {component: any, rest: any}) {
    const {reztoAuth} = userReztoStore();

    return (
        <Route
            {...rest}
            render={props => {
            return isAdmin(reztoAuth) ? <Component {...props}/> : <Redirect to="/home"/>
        }}
        >
        </Route>
    );
}

