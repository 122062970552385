import Cropper from 'react-easy-crop'
import {useCallback, useState} from "react";

export default function ReztoImageCropper ({imgUrl, setCroppedImageBlob}: {imgUrl: any, setCroppedImageBlob: any}) {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)


    async function test(croppedAreaPixels:any){
        const croppedImage = await getCroppedImg({imageSrc: imgUrl, pixelCrop: croppedAreaPixels})
        setCroppedImageBlob(croppedImage as any)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        test(croppedAreaPixels)
    }, [])


    return (
            <Cropper
                image={imgUrl}
                crop={crop}
                zoom={zoom}
                aspect={4 / 4}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
            />);
}

async function getCroppedImg({ imageSrc, pixelCrop} : {imageSrc: any, pixelCrop: any}) {
    const image: any = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const flip = { horizontal: false, vertical: false }

    if (!ctx) {
        return null
    }

    // set canvas size to match the bounding box
    canvas.width = image.width;
    canvas.height = image.height;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(image.width / 2, image.height / 2)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    const dataUrl = canvas.toDataURL('png');
    return await (await fetch(dataUrl)).blob();

}


const createImage = (url: any) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })