import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {auth} from './firebase'
import parseJwt from './common/JwtParser';
import {assertTokenValidity} from "./common/ReztoToken";
import {userReztoStore} from "./state/rootStore";
import Redirector from './Redirector';

function App() {
    const {reztoAuth, selectedRestaurant,
        restaurantChainAggregateState, productStateSlice, kioskStateSlice, menuModelStateSlice, flowStateSlice} = userReztoStore();
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            if (user != null) {
                let reztoClaims = parseJwt(user.Aa);
                reztoAuth.login({...user, reztoClaims})
                reztoAuth.setJwt({reztoJwt: reztoClaims, token: user.Aa})
            } else {
            }
            setLoading(false);
        })
        return () => {
            unsubscribe();
        };
    }, [])

    useEffect(() => {
        if (reztoAuth.user !== undefined) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    restaurantChainAggregateState.fetchRestaurantChainAggregate(reztoAuth);
                });
        }

    }, [reztoAuth.token]);

    useEffect(() => {
        if (selectedRestaurant.restaurantId && reztoAuth.user) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    menuModelStateSlice.fetchMenuModels(selectedRestaurant.restaurantId, reztoAuth);
                    flowStateSlice.fetchFlows(selectedRestaurant.restaurantId, reztoAuth);
                    kioskStateSlice.fetchKiosks(selectedRestaurant.restaurantId, reztoAuth);
                    productStateSlice.fetchProducts(selectedRestaurant.restaurantId, reztoAuth);
                });
        }

    }, [reztoAuth, selectedRestaurant]);


    return (
        <Router>
            {!loading &&
                <Redirector/>
            }
        </Router>
    );
}

export default App;
