import {Report} from "../Model/Report";
import {DateInterval} from "../Model/DateInterval";
import {StoreSlice} from "./rootStore";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";
import {GetHeaders} from "./AuthCommon";

export interface ReportState {
    reports: Report[];
    selectedDateInterval?: DateInterval;
    fetchIntervalPaginationReports: (restaurantId: string, reztoAuth: ReztoAuth, fromDate: string, toDate: string, page: number, pageLimit: number) => void;
    fetchIntervalPaginationReportsPagination: (restaurantId: string, reztoAuth: ReztoAuth, fromDate: string, toDate: string, page: number, pageLimit: number) => void;
}

interface ReportSlice {
    reportStateSlice: ReportState;
}

async function fetchReports(){

}

export const createReportSlice: StoreSlice<ReportSlice> = (set, get) => ({
    reportStateSlice: {
        selectedDateInterval: undefined,
        reports: [],
        fetchIntervalPaginationReports: (restaurantId, reztoAuth, fromDate, toDate, page, pageLimit) => {
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/restaurants/' + restaurantId
                + `/reports/interval/pagination?page=${page}&pageLimit=${pageLimit}&fromDate=${fromDate}&toDate=${toDate}`, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        reportStateSlice: {
                            ...prev.reportStateSlice,
                            selectedDateInterval: {fromDate, toDate},
                            reports: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        fetchIntervalPaginationReportsPagination: (restaurantId, reztoAuth, fromDate, toDate, page, pageLimit) => {
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/restaurants/' + restaurantId
                + `/reports/interval/pagination?page=${page}&pageLimit=${pageLimit}&fromDate=${fromDate}&toDate=${toDate}`, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        reportStateSlice: {
                            ...prev.reportStateSlice,
                            selectedDateInterval: {fromDate, toDate},
                            reports: [...prev.reportStateSlice.reports, ...result.data]
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        }
    },

});
