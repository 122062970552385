import * as React from 'react';
import NameForm from "./Form/NameForm";
import FormDialog from "../Modal/FormDialog";
import {useTranslation} from 'react-i18next';

export default function ChangeNameForm({title, previousName, onClick, onDismiss,}
                                              : { title: any, previousName: any, onClick: any, onDismiss: any }) {
    const onSubmit = (confirmed: boolean) => {
        onClick(confirmed);
        onDismiss();
    }
    const {t} = useTranslation();
    return (
        <FormDialog height={200} title={title}
                    open={true} setOpen={onDismiss}
        >
            <NameForm title={t('EDIT_NAME')} previous={previousName} onSubmit={onSubmit}
                      setChangeNameDialog={onDismiss}/>
        </FormDialog>
    );
}