import {default as React} from 'react';

import {useHistory, useParams} from 'react-router-dom';
import KioskImage from '../assets/kiosk.png';
import ReztoImage from "../common/Image/ReztoImage";
import {assertTokenValidity} from "../common/ReztoToken";
import {Kiosk} from "../Model/Kiosk";
import {userReztoStore} from "../state/rootStore";

export default function KioskCard({kiosk}: { kiosk: Kiosk }) {


    const {restaurantId} = useParams<{ restaurantId: string }>();
  
    const {reztoAuth, flowStateSlice} = userReztoStore();
    const history = useHistory()

    function publish(checked: boolean) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.publishFlow(kiosk.id,
                    reztoAuth, !checked)
            });
    }


    return (
        <div key={kiosk.id}
             onClick={() => {
                 history.push("/restaurants/" + restaurantId + "/kiosks/" + kiosk.id)
             }}
             className="cursor-pointer max-w-sm bg-gray-50 rounded overflow-hidden shadow-lg w-64 transform duration-100 hover:scale-105">
            <div className="flex flex-row justify-center">
                <ReztoImage
                    height='200px' width='100%'
                    imageUrl={KioskImage}/>
            </div>
            <div className="flex flex-row p-5 justify-between">
                <div className="text-normal text-2xl mb-2"> {kiosk.name}</div>
                {/*<ReztoSwitch defaultChecked={kiosk.openForSale} callback={publish} />*/}
            </div>
        </div>
    )
}
