import {ReportSummary} from "../Model/ReportSummary";
import {DateInterval} from "../Model/DateInterval";
import {StoreSlice} from "./rootStore";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";

export interface StatisticState {
    intervalReportSummary?: ReportSummary;
    currentDayReportSummary?: ReportSummary;
    selectedDateInterval?: DateInterval;
    fetchTodaySummary: (restaurantId: string, reztoAuth: ReztoAuth) => void;
    fetchIntervalSummary: (restaurantId: string, reztoAuth: ReztoAuth, fromDate: string, toDate: string) => void;

}

export interface StatisticSlice {
    statisticStateSlice: StatisticState
}


export const createStatisticSlice: StoreSlice<StatisticSlice> = (set, get) => ({
    statisticStateSlice: {
        selectedDateInterval: undefined,
        currentDayReportSummary: undefined,
        intervalReportSummary: undefined,
        fetchTodaySummary: (restaurantId, reztoAuth) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/restaurants/' + restaurantId
                + `/reports/interval/summary?fromDate=${new Date().toISOString().split("T")[0]}&toDate=${new Date().toISOString().split("T")[0]}`, {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        statisticStateSlice: {
                            ...prev.statisticStateSlice,
                            currentDayReportSummary: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        fetchIntervalSummary: (restaurantId, reztoAuth, fromDate, toDate) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/restaurants/' + restaurantId
                + `/reports/interval/summary?fromDate=${fromDate}&toDate=${toDate}`, {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        statisticStateSlice: {
                            ...prev.statisticStateSlice,
                            intervalReportSummary: result.data,
                            selectedDateInterval: {fromDate: fromDate, toDate: toDate}
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        }
    }
});
