import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {MenuModel} from "../Model/Menu";

export default function MenuModelCard({menuModel}: { menuModel: MenuModel }) {

    const {restaurantId} = useParams<{ restaurantId: string }>();

    return (
        <Link
            key={menuModel.id}
            to={"/restaurants/" + restaurantId + "/menu-models/" + menuModel.id}>
            <div className="max-w-sm h-40 bg-gray-200 z-0
        rounded-3xl overflow-hidden shadow-lg w-64 transform duration-100 hover:scale-105">
                <div className="flex flex-col p-5 justify-center items-center h-full">
                    <div className="text-subheader text-2xl mb-2"> {menuModel.name}</div>
                    <div className="text-normal text-xl mb-2"> {menuModel.menus.length} kategorier</div>
                </div>
            </div>
        </Link>
    )
}
