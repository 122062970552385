import React, {useEffect, useState} from "react";
import {Order, ProductRowGroup, SubProduct} from "../Model/Order";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReztoImage from "../common/Image/ReztoImage";
import {useWindowSize} from "../drawer/ReztoDrawer";
import {userReztoStore} from "../state/rootStore";
import {Kiosk} from "../Model/Kiosk";
import {Product} from "../Model/Product";
function ReceiptCard({orderId}: { orderId: string }) {

    const {productStateSlice, orderStateSlice, kioskStateSlice} = userReztoStore();
    const orders = orderStateSlice.orders;
    const kiosks = kioskStateSlice.kiosks;

    const order = orders.find((order: Order) => order.id === orderId);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [smallCard, setSmallCard] = useState(false);
    const size = useWindowSize();
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };


    useEffect(() => {
        if (size.width < 1000) {
            if(!smallCard){
                setSmallCard(true)
            }
        }else{
            if(smallCard){
                setSmallCard(false)
            }
        }
    }, [size])


    if (!order) {
        return (<></>)
    }


    function getOrderProductGroupTotal(orderProductGroup: ProductRowGroup) {
        let total = orderProductGroup.mainProduct.unitPrice.amount.amount;
        orderProductGroup.subProducts.forEach((subProduct: SubProduct) => total += subProduct.unitPrice.amount.amount)
        return total / 100
    }

    function paymentMethodToString(str: string){
        switch(str){
            case "SWISH":
                return "Swish";
            case "CARD_DIRECT2INTERNET_MIURA_M010":
            case "CARD_IZETTLE":
            case "CARD_VERIFONE_P400":
            case "CARD_NETS_LANE_3000":
                return "Kort";
            case "EXTERNAL_CASH":
                return "Kontant";
            case "EXTERNAL_GIFTCARD":
            case "GIFTCARD":
                return "Presentkort";
            case "EXTERNAL_GIFTCARD":
                return "Presentkort";
            default:
                return "Annat";
        }
    }

    function orderStatusToString(o: Order){
        let text = "";
        const statusColor = "#000";
        if(o.delivered){
            text = "Levererad";
            return <h1 className="text-normal rounded-md p-2 ml-10 font-bold bg-green-500">{text}</h1>
        }else if(o.ready){
            text = "Mat klar";
            return <h1 className="text-normal rounded-md p-3 ml-10 font-bold bg-green-300">{text}</h1>
        }else if(o.payed){
            text = "Väntar";
            return <h1 className="text-normal rounded-md p-3 ml-10 font-bold bg-yellow-600">{text}</h1>
        }
            
        text = "Okänd";
        return <h1 className="text-normal rounded-md ml-10 font-bold bg-rose-500">{text}</h1>
        
        
    }

    if(kiosks.filter((x: Kiosk) => x.id === order.kioskId).length == 0 || productStateSlice.products.length == 0){
        return <></>
    }
    return (
        <div className="border-2 rounded-b rounded-t">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    
                    <div className="flex flex-row justify-between w-full">
                        <div className="flex flex-row p-2">
                            <h1 className="text-normal text-xl ml-2 mr-2 mt-1 font-bold ">#{order.queueNumber}</h1>
                            {!smallCard &&
                                orderStatusToString(order)
                            }
                            {!smallCard &&
                                <h3 className="text-normal ml-10 mt-2 font-bold">{paymentMethodToString(order.purchase.payments[0].paymentType)}</h3>
                            }
                            <h3 className="text-normal text-xl ml-20 mr-20 mt-1 font-bold">{order.purchase.amount.amount / 100} kr</h3>
                        </div>

                        <div className="flex flex-row justify-between">
                            <h3 className="text-normal mr-10 mt-4 font-bold">{(order.timestampPayed.toString()).split(".")[0].split("T")[0]}</h3>
                            <h3 className="text-normal mr-10 mt-4 font-bold">{(order.timestampPayed.toString()).split(".")[0].split("T")[1].substring(0, 5)}</h3>
                            <h3 className="text-normal mr-10 mt-4 font-bold">{kiosks.filter((x: Kiosk) => x.id === order.kioskId)[0]?.displayName}</h3>
                            
                        </div>
                    </div>
                    

                    {/*<SwishSvg width={50} height={50}/>*/}
                </AccordionSummary>
                <AccordionDetails>
                    {
                        order.productRowGroups.map((orderProductGroup: ProductRowGroup, index: number) => {
                            return (
                                <div key={index} className={"mb-2 border-b-2"}>
                                    <div className="flex flex-row justify-start items-center pl-3">
                                        <div className="pb-3">
                                            <ReztoImage imageUrl={productStateSlice.products.filter((x: Product) => x.id === orderProductGroup.mainProduct.productId)[0].imageUrl} height={40} width={40}/>
                                        </div>
                                        <h1 className="text-normal text-xl ml-2 mr-2 font-bold">{"x" + orderProductGroup.mainProduct.quantity + " " + orderProductGroup.mainProduct.name}</h1>
                                        <h2 className="text-normal text-xl font-bold">{orderProductGroup.mainProduct.unitPrice.salePrice.amount / 100} kr</h2>
                                    </div>
                                    {
                                        orderProductGroup.subProducts.map((subProduct: SubProduct) => {
                                            return (
                                                <div key={subProduct.name}
                                                     className="flex flex-row justify-between pl-3">
                                                    <h2>+ {subProduct.name}</h2>
                                                    <h2>{subProduct.unitPrice.salePrice.amount / 100} kr</h2>
                                                </div>
                                            );
                                        })
                                    }
                                    <div className="flex flex-row justify-between pl-3">
                                        <h1 className="text-normal text-xl font-bold">Totalt</h1>
                                        <h2 className="text-normal text-xl font-bold">{getOrderProductGroupTotal(orderProductGroup)} kr</h2>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="flex flex-row justify-between pl-3">
                        <h1 className="text-normal text-2xl font-bold">Totalt</h1>
                        <h2 className="text-normal text-2xl font-bold">{order.purchase.amount.amount / 100} kr</h2>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default ReceiptCard;

