
export default function CheckBoxNormal(
    {title, values, register, formControlName}: {title: string,
        values: any, register: any, formControlName: any}) {
    if(!values.length){
        values = Object.entries(values)
        return (
            <div className="border-2 p-2 mb-2">
                <label className="input-label-normal">{title}</label>
                <div className="mt-2">
                    {
                        values.map((value: any) => {
                            return (
                                <label key={value} className="input-labelinline-flex items-center pr-5">
                                    <input type="checkbox" className="form-radio" name="accountType" value={value[1]} {...register(formControlName)} />
                                    <span className="ml-2 text-mainFont">{value[0]}</span>
                                </label>
                            )
                        })
                    }
                </div>
            </div>
        )
    }else {
        return (
            <div className="border-2 p-2 mb-2">
                <label className="input-label-normal">{title}</label>
                <div className="mt-2">
                    {
                        values.map((value: any) => {
                            return (
                                <label key={value} className="input-labelinline-flex items-center pr-5">
                                    <input type="checkbox" className="form-radio" name="accountType"
                                           value={value} {...register(formControlName)} />
                                    <span className="ml-2 text-mainFont">{value}</span>
                                </label>
                            )
                        })
                    }
                </div>
            </div>
        )
    }


}