import React from "react";
import {
    DragDropContext,
    Draggable,
    DraggingStyle,
    Droppable,
    DropResult,
    NotDraggingStyle
} from "react-beautiful-dnd";

export interface ReorderItem {
    id: string;
    title: string;
    content: any;
}

const reorder = (
    list: ReorderItem[],
    startIndex: number,
    endIndex: number
): ReorderItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 12;

const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: isDragging ? "lightgreen" : "#f7f7f2",

    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    background: "lightgrey",
    padding: grid,
    width: '100%',
});

export default function ReorderComponent({ state, setState }:
    { state: ReorderItem[], setState: React.Dispatch<React.SetStateAction<ReorderItem[]>> }) {

    const onDragEnd = (result: DropResult): void => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items: ReorderItem[] = reorder(
            state,
            result.source.index,
            result.destination.index
        );

        setState(items);
    };

    return (

        <DragDropContext onDragEnd={(result) => {
            onDragEnd(result)
        }}>
            <Droppable droppableId="droppable">
                {(provided, snapshot): JSX.Element => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {state.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot): JSX.Element => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={
                                            getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )
                                        }
                                    >
                                        {item.title}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

    );
};
