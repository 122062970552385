import React, {useEffect, useState} from "react";

import FlowCard from "./FlowCard";
import {Flow} from "../Model/Flow";
import {useParams} from "react-router-dom";
import FormDialog from "../Modal/FormDialog";
import FlowForm from "./Forms/FlowForm";
import {assertTokenValidity} from "../common/ReztoToken";
import {useTranslation} from "react-i18next";
import {userReztoStore} from "../state/rootStore";

function FlowList() {

    const {reztoAuth, flowStateSlice} = userReztoStore();
    let flows = flowStateSlice.flows;
    const {restaurantId} = useParams<{ restaurantId: string }>()
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (restaurantId) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    flowStateSlice.fetchFlows(restaurantId, reztoAuth);
                });

        }
    }, [reztoAuth, restaurantId]);


    return (
        <>
            <div className="flex justify-between">
                <div className="flex flex-col justify-top">
                    <h1 className="text-header mb-2 mt-10">{t('FLOWS_HEADER').toUpperCase()}</h1>
                    <h1 className="text-normal text-xl mb-2">{t('FLOWS_SUB')}</h1>
                </div>
                <div className="flex flex-col justify-end">
                    <button onClick={() => {setCreateDialogOpen(true)}}
                     className="dropdown-button m-4">Nytt flöde</button>
                </div>

                {createDialogOpen &&
                <FormDialog title={"Nytt flöde"} height={350}
                            open={createDialogOpen} setOpen={setCreateDialogOpen}
                >
                    <FlowForm
                        setCreateDialogOpen={setCreateDialogOpen}/>
                </FormDialog>
                }
            </div>


            <hr className="border-secondary border-t-2 border-solid"/>

            <div className="flex flex-wrap pt-5 gap-3">
                {
                    flows.map((flow: Flow, index: number) => {
                        return <FlowCard flow={flow} key={index}/>
                    })
                }
            </div>

        </>

    );
}

export default FlowList;

