import React, {useEffect, useState} from "react";

import {useParams} from "react-router-dom";
import KioskCard from "./KioskCard";
import {assertTokenValidity} from "../common/ReztoToken";
import FormDialog from "../Modal/FormDialog";
import KioskForm from "./KioskForm";
import {userReztoStore} from "../state/rootStore";
import {Kiosk} from "../Model/Kiosk";

function KioskList() {

    const {reztoAuth, kioskStateSlice} = userReztoStore();
  
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    useEffect(() => {
        if (restaurantId) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    kioskStateSlice.fetchKiosks(restaurantId, reztoAuth);
                });

        }
    }, [reztoAuth, restaurantId]);

    return (

        <>

            <div className="flex justify-between">
                <h1 className="text-header text-6xl m-2 mt-10">Enheter</h1>
                <div className="flex flex-col justify-center">
                    <button onClick={() => {
                        setCreateDialogOpen(true)
                    }}
                            className="dropdown-button h-10 mt-3">Ny enhet
                    </button>
                </div>

            </div>

            {createDialogOpen &&
                <FormDialog title={"Ny enhet"}
                            open={createDialogOpen} setOpen={setCreateDialogOpen}
                >
                    <KioskForm
                        setCreateDialogOpen={setCreateDialogOpen}/>
                </FormDialog>
            }


            <hr className="border-secondary border-t-2 border-solid"/>
            <div className="flex flex-wrap pt-5 gap-3">
                {
                    kioskStateSlice.kiosks.map((kiosk: Kiosk) => {
                        return (
                            <KioskCard key={kiosk.id} kiosk={kiosk}/>
                        );
                    })
                }
            </div>

        </>


    );
}

export default KioskList;

