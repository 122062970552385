import Paper from '@mui/material/Paper'
import React, {useState} from 'react'
import ProductFilter from '../../product/ProductFilter';
import {useEffect} from 'react';
import './ProductSelectionCard.css';
import {Product} from "../../Model/Product";
import {userReztoStore} from "../../state/rootStore";
import {FilterState} from "../../state/FilterSlice";

export default function ProductSelectionCard({prevProductId, form, withName = true}:
                                                 { prevProductId?: string, form: any, withName?: boolean }) {
    const {productStateSlice, filterStateSlice} = userReztoStore();
    let prevProduct = productStateSlice.products.find((product: Product) => product.id === prevProductId)
    const [product, setProduct] = useState(prevProduct);
    const filterState: FilterState = filterStateSlice;
    const [selection, setSelection] = useState('');


    useEffect(() => {
        if (prevProduct) {
            form.setFieldValue('product', prevProduct);
        }
    }, []);


    const [filteredProducts, setFilteredProducts] = useState(productStateSlice.products);

    useEffect(() => {
        if (filterState.productFilter === "" || filterState.productFilter === undefined) {
            setFilteredProducts(productStateSlice.products)
        } else if (filterState.productFilter) {
            setFilteredProducts(
                productStateSlice.products.filter((product: Product) => product.categories !== null
                    && product.categories.includes(filterState.productFilter!)))
        }
    }, [productStateSlice.products, filterState.productFilter])


    return (
        <>
            <Paper onClick={() => {
                setSelection('product')
            }}
                   style={{
                       width: '100%', height: '100%', display: 'flex',
                       cursor: 'pointer',
                       alignItems: 'center',
                       justifyContent: 'space-around', flexDirection: 'column'
                   }}>
                {product &&
                    <>
                        <div className="flex flex-row justify-between">
                            <img src={product.imageUrl}
                                 className="rounded-full selectedImg p-3">
                            </img>
                        </div>
                    </>
                }

                {!product &&
                    <>
                        <h2 style={{marginLeft: 'auto', marginRight: 'auto'}}>Välj produkt</h2>
                    </>
                }

            </Paper>
            {selection === 'product' &&
                <div>
                    <div className="absolute z-50 overflow-y-scroll
                  bg-secondary top-0 left-0 w-full min-h-full max-h-96">
                        <div className="sticky top-0 z-30">
                            <ProductFilter/>
                        </div>
                        <div className="flex flex-col gap-2 p-3">
                            {
                                filteredProducts.map((product: Product) => {
                                    return (
                                        <div key={product.id} onClick={() => {
                                            setProduct(product);
                                            form.setFieldValue('product', product);
                                            // register('product', {value: product});
                                            setSelection('');
                                        }}
                                             className="text-center text-3xl cursor-pointer text-mainFont flex flex-row justify-center">
                                            <h1 className="product-selection-item dropdown-button">{product.name}</h1>
                                        </div>

                                    )
                                })
                            }
                        </div>

                    </div>
                    <button className="dropdown-button absolute z-50 bottom-1 left-1 " onClick={() => {
                        setSelection('')
                    }}>Tillbaka
                    </button>

                </div>

            }
        </>

    )
}
