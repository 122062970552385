import {userReztoStore} from "./rootStore";
import {SNACKBAR_ENUM} from "./Snackbar";

export function ShowSnackbar(message: SNACKBAR_ENUM){
    console.log(message.includes("FAILED"))
    userReztoStore.setState((prev: any) => ({
        ...prev,
        snackbarStateSlice:{
            ...userReztoStore.getState().snackbarStateSlice,
            open: true,
            message: message.toString(),
            success: !message.includes("FAILED")
        },}));
}