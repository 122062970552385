import React, { useState} from "react";
import ReztoImage from "../common/Image/ReztoImage";
import './ImageBankPage.css'
import ConfirmDialog from "../Modal/ConfirmDialog";
import {useTranslation} from "react-i18next";
import {assertTokenValidity} from "../common/ReztoToken";

import {ReztoEngineImage} from "../Model/ReztoEngineImage";
import {userReztoStore} from "../state/rootStore";
export default function ImageBankCard({image}: {image: ReztoEngineImage}) {

    const {t}  = useTranslation();
    const {reztoAuth, imageStateSlice} = userReztoStore();
    const [hover, setHover] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  

    function onSubmit(confirmed: boolean){
        if(confirmed){
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    imageStateSlice.deleteImage(reztoAuth, image.id);
                });
        }
    }


    return <div className="image-bank-card relative"  onMouseLeave={() => setHover(false)}
                onMouseEnter={() => setHover(true)}>

        {deleteDialogOpen &&
            <ConfirmDialog
                title={t('REMOVE_IMAGE_CONFIRMATION')}
                callBack={(confirmed: boolean) => {
                    onSubmit(confirmed)
                }}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
            />
        }

        <div className={"image-bank-image rounded-2xl" + (hover ? " image-bank-image--blurry" : "")}>
            <ReztoImage imageUrl={image.url} height={300} width={300}/>
        </div>
            <div className={"image-bank-card-panel absolute" + (hover ? " image-bank-card-panel--active": "")}>
                <button onClick={() => setDeleteDialogOpen(true)} className="dropdown-button ">Radera</button>
            </div>

    </div>
}