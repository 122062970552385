import FormDialog from "../Modal/FormDialog";
import React, {useEffect, useState} from "react";
import {assertTokenValidity} from "../common/ReztoToken";
import {useTranslation} from "react-i18next";

import {ReztoEngineImage} from "../Model/ReztoEngineImage";
import LazyLoad from "react-lazyload";
import ReztoImage from "../common/Image/ReztoImage";
import firebase from 'firebase/app'
import 'firebase/storage'
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import ReztoImageCropper from "./ReztoImageCropper";
import Compressor from "compressorjs";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import {userReztoStore} from "../state/rootStore";
import {reztoDefaultImageURL} from "./EditProduct";
export default function ImagePicker({setSelectedImageUrl, selectedImageUrl}: {setSelectedImageUrl: any, selectedImageUrl: string}) {

    const {t} = useTranslation();
    const {selectedRestaurant} = userReztoStore();
    const {reztoAuth, imageStateSlice} = userReztoStore();
    const images: ReztoEngineImage[] = imageStateSlice.images;
    const [imageSelectDialogOpen, setImageSelectDialogOpen] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [originalImage, setOriginalImage] = useState<String |undefined>(undefined);
    const [croppedImageBlob, setCroppedImageBlobBlob] = useState<Blob |undefined>(undefined);
    const storage = firebase.storage()



    useEffect(() => {
        setUploadInProgress(false);
        setOriginalImage("");
        setCroppedImageBlobBlob(undefined)
    }, [images.length])



  


    useEffect(() => {

        if (selectedRestaurant) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    imageStateSlice.fetchImages([selectedRestaurant.restaurantId, selectedRestaurant.restaurantChainId],
                        reztoAuth);
                });
        }
    }, [selectedRestaurant])


    const handleImageAsFile = (e: any) => {
        var file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend =  function() {
            setOriginalImage(reader.result as any);
            e.target.value = null;
        }
        reader.readAsDataURL(file);
    }


    function compressAndUpload(){

        if(croppedImageBlob){
            new Compressor(croppedImageBlob, {
                quality: 0.6,
                width: 800,
                height: 800,
                success(result) {
                    setUploadInProgress(true);
                    const fileName = selectedRestaurant.restaurantId + "_" + (+new Date()).toString();
                     const uploadTask = storage.ref(`/images/${fileName}`).put(result)
                    //initiates the firebase side uploading
                    uploadTask.on('state_changed',
                        (snapShot) => {
                            console.log(snapShot)
                        }, (err) => {
                            console.log(err)
                        }, () => {
                            storage.ref('images').child(fileName).getDownloadURL()
                                .then(fireBaseUrl => {
                                    assertTokenValidity(reztoAuth)
                                        .then((_) => {
                                            imageStateSlice.createImage(reztoAuth,
                                                [selectedRestaurant.restaurantChainId, selectedRestaurant.restaurantId],
                                                fireBaseUrl);
                                        });
                                })
                        })
                },
                error(err) {
                    console.log(err.message);
                },
            });
        }else{
            console.log("no originalImage selected");
        }
    }

    return (

        <>
            <button type="button" onClick={() => setImageSelectDialogOpen(true)} className="dropdown-button">{selectedImageUrl === "" ? "Lägg till bild" : "Byt bild"}
            </button>

            {imageSelectDialogOpen &&
                <FormDialog height={1000} width={1100} title={"Välj bild"} open={imageSelectDialogOpen}
                            setOpen={setImageSelectDialogOpen}>
                    <div className="flex flex-row justify-between p-5 sticky top-0 bg-white" style={{zIndex: 99999}}>
                        {originalImage &&
                            <button onClick={() => compressAndUpload()}  className="dropdown-button">Ladda upp ny bild</button>
                        }

                        {!originalImage &&
                            <>
                                <label htmlFor="file-upload" className=" dropdown-button cursor-pointer">
                                    Ladda upp en ny bild
                                </label>
                                <input className="hidden" id="file-upload" type="file" name="myImage" onChange={handleImageAsFile}/>
                            </>
                        }

                        {originalImage &&
                           <div className="cursor-pointer" onClick={() => {setOriginalImage(undefined); setCroppedImageBlobBlob(undefined);}}>
                               <ClearRoundedIcon style={{fill: "white"}}/>
                           </div>
                        }
                    </div>

                    <hr className="border-primary border-t-2 border-solid pb-2"/>
                    <div className="flex flex-row flex-wrap gap-5 shadow-2xl p-5 h-screen mb-10">
                        {(originalImage && !uploadInProgress) &&
                            <ReztoImageCropper imgUrl={originalImage} setCroppedImageBlob={setCroppedImageBlobBlob}/>
                        }
                        {(!uploadInProgress && !originalImage) &&
                            images.map(i => {
                                return <LazyLoad key={i.id}  once>
                                    <div onClick={() => {
                                        setSelectedImageUrl(i.url);
                                        setImageSelectDialogOpen(false);
                                    }

                                    } className="hover:scale-110 transform duration-200 cursor-pointer">
                                        <ReztoImage imageUrl={i.url} height={300} width={300}/>
                                    </div>

                                </LazyLoad>
                            })
                        }
                        {uploadInProgress &&
                            <CircularProgress style={{width: 600, height: 600}}/>
                        }
                    </div>
                </FormDialog>
            }
        </>
    );
}