import React, {useState} from "react";
import './ProfileNavbar.css'
import OutsideClick from "../common/OutsideClick";
import {userReztoStore} from "../state/rootStore";
import {auth} from "../firebase";
import {useHistory} from "react-router-dom";
import {ReztoAuthState} from "../state/AuthSlice";
import {isAdmin} from "../common/Auth";

export default function ProfileNavbar() {

    const [show, setShow] = useState(false);
    const {reztoAuth} = userReztoStore();
    const history = useHistory()

    async function logOut() {
        try {
            auth.signOut().then(result => {
                reztoAuth.logout()
                history.push("/signin")
            })
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <OutsideClick clicked={function () {
            setShow(false)
        }}>
            <div onClick={() => setShow(!show)} className="sec-center">
                <div
                    className="cursor-pointer select-none flex flex-col justify-center bg-primary dropdown w-12 h-12 rounded-full">
                    <h1 className="text-2xl">{reztoAuth.email?.charAt(0).toUpperCase()}</h1>
                </div>

                <div className={"section-dropdown " + (show ? "dropdown-selected" : "")}>
                    <div onClick={() => {
                        logOut();
                    }} className="profile-drop-down-item-container ">
                        <a href="#">LOGGA UT<i className="profile-drop-down-menu"/></a>
                    </div>

                    {isAdmin(reztoAuth) &&
                        <div onClick={() => {
                            history.push("/users")
                        }} className="profile-drop-down-item-container">
                            <a href="#">ANVÄNDARE<i className="profile-drop-down-menu "/></a>
                        </div>
                    }


                    <div onClick={() => {
                        history.push("/home")
                    }} className="profile-drop-down-item-container">
                        <a href="#">HEM<i className="profile-drop-down-menu "/></a>
                    </div>
                </div>
            </div>
        </OutsideClick>

    );
}


