import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog({callBack, title, open, setOpen}:
                                                { callBack: any, title: string, open: any, setOpen: any }) {


    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        callBack(true)
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle style={{fontFamily: 'Grold-light', fontSize: '2rem'}}>{title}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>Nej</Button>
                <Button onClick={handleConfirm}>Ja</Button>
            </DialogActions>
        </Dialog>
    );
}
