import {useParams} from "react-router";
import {userReztoStore} from "../state/rootStore";
import {Route, Switch, useHistory} from "react-router-dom";
import RestaurantChainPage from "../RestaurantChain/RestaurantChainPage";
import React from "react";
import path from "path";


export default function KioskPage() {

    const {kioskStateSlice} = userReztoStore();
    const {restaurantId, kioskId} = useParams<{ restaurantId: string, kioskId: string }>();
    const kiosk = kioskStateSlice.kiosks.find((r: any) => r.id === kioskId);
    const history = useHistory();
    if (kiosk) {
        return (
            <>
                <h1 className="text-header m-2 mt-10">{kiosk.name}</h1>
                <hr className="border-primary border-t-2 border-solid"/>
                <div className="flex justify-between pt-5">
                    {kiosk.openForSale &&
                        <h1 className="text-mainFont font-bold">Enhet öppnad: {kiosk.lastReportSequenceIncrementAt}</h1>
                    }
                 <button onClick={() => {history.push(`/restaurants/${restaurantId}/kiosks/${kioskId}/configuration`)}} className="dropdown-button">Redigera gränssnitt</button>

                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }
}
