import React, {useEffect} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {Order} from "../Model/Order";
import {assertTokenValidity} from "../common/ReztoToken";
import {userReztoStore} from "../state/rootStore";

export default function DayStatistics() {

    const {restaurantId} = useParams<{ restaurantId: string }>();
    const {t} = useTranslation();
    const {reztoAuth, statisticStateSlice, orderStateSlice} = userReztoStore();
    const orders = orderStateSlice.orders.filter((order: Order) => order.timestampCreatedOnServer.toString().split("T")[0] === new Date().toISOString().split("T")[0]);
    const today = new Date();
    useEffect(() => {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                orderStateSlice.fetchOrders(restaurantId, reztoAuth, today.toISOString().split("T")[0]);
                statisticStateSlice.fetchTodaySummary(restaurantId, reztoAuth);
            });
    }, [restaurantId])


    let numberOfSwishPayments = 0;
    let numberOfCardPayments = 0;

    orders.forEach((order: Order) => {
        numberOfSwishPayments += order.purchase.payments.filter(payment => payment.paymentType === "SWISH").length;
        numberOfCardPayments += order.purchase.payments.filter(payment => payment.paymentType === "CARD_NETS_LANE_3000").length;
    });


    if (!statisticStateSlice.currentDayReportSummary || statisticStateSlice.currentDayReportSummary?.totalSale.amount === 0) {

        return (
            <div className="flex flex-col justify-center text-center absolute"
                 style={{left: '40%', top: '50%'}}>
                <h1 className="text-normal text-2xl align-middle text-mainFont">
                    {t("DASHBOARD_NO_SALE_YET")}</h1>
            </div>
        );

    }

    return (
        <div className="flex flex-col pt-10">

            <div className="flex md:flex-row sm:flex-col xs:flex-col justify-start">

                <div className="border-2 rounded-lg p-10 mr-10 mb-5">
                    <h1 className="text-mainFont text-1xl font-light mb-2 self-center">{t('TOTAL_SALE')}</h1>
                    <h1 className="text-mainFont text-3xl font-bold text-center">{statisticStateSlice.currentDayReportSummary.totalSale.amount / 100} kr</h1>
                </div>

                <div className="border-2 rounded-lg p-10 mr-10 mb-5">
                    <h1 className="text-mainFont text-1xl font-light mb-2 self-center">{t('DASHBOARD_UPSELL')}</h1>
                    <h1 className="text-mainFont text-3xl font-bold text-center">{statisticStateSlice.currentDayReportSummary.totalUpsell.amount / 100} kr</h1>
                </div>

                <div className="border-2 rounded-lg p-10 mr-10 mb-5">
                    <h1 className="text-mainFont text-1xl font-light mb-2 self-center">{t('NUMBER_OF_PRODUCTS')}</h1>
                    <h1 className="text-mainFont text-3xl font-bold text-center">{statisticStateSlice.currentDayReportSummary.numberOfProducts} st</h1>
                </div>


            </div>

            <div className="flex md:flex-row sm:flex-col xs:flex-col justify-start mt-10">

                <div className="border-2 rounded-lg p-10 mr-10 mb-5">
                    <h1 className="text-mainFont text-1xl font-light mb-2 self-center">{t('NUMBER_OF_PAYMENTS')}</h1>
                    <h1 className="text-mainFont text-3xl font-bold text-center">{statisticStateSlice.currentDayReportSummary.numberOfOrders} st</h1>
                </div>

                <div className="border-2 rounded-lg p-10 mr-10 mb-5">
                    <h1 className="text-mainFont text-1xl font-light mb-2 self-center">{t('NUMBER_OF_CARD_PAYMENTS')}</h1>
                    <h1 className="text-mainFont text-3xl font-bold text-center">{numberOfCardPayments} st</h1>
                </div>

                <div className="border-2 rounded-lg p-10 mr-10 mb-5">
                    <h1 className="text-mainFont text-1xl font-light mb-2 self-center">{t('NUMBER_OF_SWISH_PAYMENTS')}</h1>
                    <h1 className="text-mainFont text-3xl font-bold text-center">{numberOfSwishPayments} st</h1>
                </div>

            </div>

        </div>
    );
}
