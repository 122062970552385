import * as React from 'react';
import ConfirmDialog from "../Modal/ConfirmDialog";

export default function DeleteForm({confirmText, onClick, onDismiss}
                                          : { confirmText: any, onClick: any, onDismiss: any }) {
    const onSubmit = (confirmed: boolean) => {
        onClick(confirmed);
        onDismiss();
    }
    return (
        <ConfirmDialog
            title={confirmText}
            callBack={(confirmed: boolean) => {
                onSubmit(confirmed)
            }}
            open={true}
            setOpen={onDismiss}
        />
    );
}