import TextField from '@mui/material/TextField'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function InputField({
                                       name, title, control,
                                       type = "text",
                                       rules = {required: 'Vänligen fyll i ett meddelande'}
                                   }: {
    name: string,
    title: string, control: any, type?: string, rules?: any
}) {
    return (
        <div className="p-1">
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={rules}
                render={({field: {onChange, value}, fieldState: {error}}) => {
                    return (<TextField
                        style={{width: '100%'}}
                        label={title}
                        variant="filled"
                        type={type}
                        value={value || ""}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                    />)
                }}
            />
        </div>

    )
}
