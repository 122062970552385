import React, {useEffect, useState} from "react";
import {userReztoStore} from "../state/rootStore";
import './UserPage.css';
import {UserState} from "../state/UserSlice";
import {ReztoAuthState} from "../state/AuthSlice";
import {Group, Select, Stepper, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {RestaurantChainAggregateState} from "../state/RestaurantChainAggregateSlice";
import FormDialog from "../Modal/FormDialog";
import {UserRestaurantSelection} from "./UserRestaurantSelection";
import {REZTO_ACCESS_ROLES, REZTO_ROLE} from "../common/Constants";
import Navbar from "../navbar/Navbar";
import {useHistory} from "react-router-dom";

export default function UserForm() {

    const {userStateSlice, reztoAuth, restaurantChainAggregateState} = userReztoStore();
    const userState: UserState = userStateSlice;
    const auth: ReztoAuthState = reztoAuth;
    const chainAggregate: RestaurantChainAggregateState = restaurantChainAggregateState;
    const [showModal, setShowModal] = useState(false);
    const [restaurantChainId, setRestaurantChainId] = useState('');
    const history = useHistory();

    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const [reztoRole, setReztoRole] = useState<any>(REZTO_ROLE.USER);
    const [accessRole, setAccessRole] = useState<any>(REZTO_ACCESS_ROLES.RESTAURANT_OWNER);

    useEffect(() => {
        userState.fetchUsers(reztoAuth);
    }, [auth.email])

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
            restaurantChains: []
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });



    return (

        <>
            <Navbar/>
            <div className="flex flex-col mt-32 items-center">
                <h1 className="select-none text-large text-5xl p-5">NY ANVÄNDARE</h1>
                <div className="w-1/3 border-2 p-3 ">
                    <>
                        <Stepper color="green" active={active} onStepClick={setActive} breakpoint="sm">
                            <Stepper.Step label="Skapa konto">
                                <form onSubmit={form.onSubmit((values) => console.log(values))}>
                                    <TextInput
                                        style={{}}
                                        autoComplete="off"
                                        required
                                        label="Email"
                                        placeholder="your@email.com"
                                        {...form.getInputProps('email')}
                                    />
                                    <TextInput
                                        autoComplete="none"
                                        required
                                        label="Password"
                                        type="password"
                                        {...form.getInputProps('password')}
                                    />
                                </form>
                            </Stepper.Step>
                            <Stepper.Step label="Konfiguera rättigheter">
                                <Select
                                    label="Rezto roll"
                                    placeholder="Välj en"
                                    value={reztoRole}
                                    onChange={setReztoRole}
                                    data={[
                                        {value: REZTO_ROLE.USER, label: REZTO_ROLE.USER},
                                        {value: REZTO_ROLE.REZTO_SUPER_ADMIN, label: REZTO_ROLE.REZTO_SUPER_ADMIN}
                                    ]}
                                />
                                <Select
                                    label="Access roll"
                                    placeholder="Välj en"
                                    value={accessRole}
                                    onChange={setAccessRole}
                                    data={[
                                        {value: REZTO_ACCESS_ROLES.RESTAURANT_OWNER, label: REZTO_ACCESS_ROLES.RESTAURANT_OWNER},
                                        {
                                            value: REZTO_ACCESS_ROLES.RESTAURANT_CHAIN_OWNER,
                                            label: REZTO_ACCESS_ROLES.RESTAURANT_CHAIN_OWNER
                                        }
                                    ]}
                                />
                            </Stepper.Step>
                            <Stepper.Step label="Välj restauranger">

                                {reztoRole === REZTO_ROLE.REZTO_SUPER_ADMIN &&
                                    <div className="flex flex-row justify-center p-5">
                                        <h1 className="text-normal text-2xl">
                                            Du valde super admin; du kommer att ha fulla
                                            rättigheter.
                                        </h1>
                                    </div>

                                }
                                {reztoRole !== REZTO_ROLE.REZTO_SUPER_ADMIN &&
                                    <>
                                        <h1 key={"asd"} className="text-normal pt-3 pb-3">Välj restauranger</h1>
                                        {
                                            chainAggregate.restaurantChains.map((r, index) => {
                                                return <h1 key={r.id + index} onClick={() => {
                                                    setRestaurantChainId(r.id);
                                                    setShowModal(true);
                                                }} className="user-page-list-item border-2 p-2 mt-2">
                                                    {r.name}
                                                </h1>
                                            })
                                        }


                                        <FormDialog title={"Välj restauranger"} open={showModal} setOpen={setShowModal}>
                                            {showModal &&
                                                <UserRestaurantSelection userAccessRole={accessRole} form={form}
                                                                         setShowModal={setShowModal}
                                                                         restaurantChainId={restaurantChainId}/>
                                            }

                                        </FormDialog>
                                    </>
                                }

                            </Stepper.Step>
                            <Stepper.Completed>
                                <div className="flex flex-row justify-center p-5">
                                    <h1 className="text-normal text-2xl">Färdig! Din användare är redo att skapas. </h1>
                                </div>

                            </Stepper.Completed>
                        </Stepper>

                        <Group position="center" mt="xl">
                            <button className="dropdown-button" onClick={() => {
                                if(active == 2){
                                    form.values.restaurantChains = [];
                                }

                                if(active == 0){
                                    history.push("/users")
                                }else{
                                    prevStep();
                                }


                            }}>Tillbaka
                            </button>
                            {active < 3 &&

                                <button className="dropdown-button" onClick={nextStep}>Nästa</button>


                            }

                            {active === 3 &&

                                <button className="dropdown-button" onClick={() => {
                                    const reztoUser: any = {
                                        email: form.values.email,
                                        password: form.values.password,
                                        reztoRole: reztoRole,
                                        userAccessRole: accessRole,
                                        restaurantChains: form.values.restaurantChains,
                                        uid: null,

                                    }
                                    userState.createUser(reztoUser, auth, history);
                                }}>Skapa användare</button>

                            }
                        </Group>
                    </>
                </div>
            </div>
        </>
    );
}
