import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {userReztoStore} from "../state/rootStore";
import {SnackbarState} from "../state/Snackbar";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ReztoSnackbar() {
    const [open, setOpen] = React.useState(false);
    const {snackbarStateSlice} = userReztoStore();
    const snackbarState: SnackbarState = snackbarStateSlice;

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        snackbarState.setClose();
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={snackbarState.open} autoHideDuration={snackbarState.success ? 2000 : 5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={snackbarState.success ? "success" : "error"} sx={{ width: '100%' }}>
                    {
                        snackbarState.message
                    }
                </Alert>
            </Snackbar>
        </Stack>
    );
}