import React from 'react'
import {Product} from "../Model/Product";

import {userReztoStore} from "../state/rootStore";
import {FilterState} from "../state/FilterSlice";

export default function ProductFilter() {

  
    const {productStateSlice, filterStateSlice} = userReztoStore();
    const products: any = productStateSlice.products;
    const filter: FilterState = filterStateSlice;

    const categories = Array.from(new Set([].concat.apply([],
        products.map((product: Product) => product.categories))).values());

    return (
        <select className="dropdown-button h-10 ml-3 mt-2" onChange={event => {
            filterStateSlice.setProductFilter(event.target.value);
        }}>
            <option value="">Filtrera</option>
            {
                [...categories].map(prod => {
                    return <option defaultChecked={prod === filter.productFilter} key={prod} value={prod}>{prod}</option>
                })
            }
        </select>
    )
}
