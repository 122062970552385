import {StoreSlice} from "./rootStore";

export interface FilterState {
    productFilter?: string
    setProductFilter: (filter: string) => void;
}

interface FilterSlice {
    filterStateSlice: FilterState;
}

export const createFilterSlice: StoreSlice<FilterSlice> = (set, get) => ({
    filterStateSlice: {
        productFilter: undefined,
        setProductFilter: (filter: string) => {
           set( (prev) => (
               {
                   ...prev,
                   filterStateSlice: {
                       ...prev.filterStateSlice,
                       productFilter: filter
                   }
               }
           ))
        },
    }
});
