import {DateInterval} from "../Model/DateInterval";
import {StoreSlice} from "./rootStore";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";
import {Order} from "../Model/Order";

export interface OrderState {
    orders: Order[];
    selectedReceiptInterval?: DateInterval;
    fetchOrders: (restaurantId: string, reztoAuth: ReztoAuth, date: string) => void;
    fetchOrdersInterval: (restaurantId: string, reztoAuth: ReztoAuth, fromDate: string, toDate: string, page: number, pageLimit: number) => void;
    fetchOrdersIntervalPagination: (restaurantId: string, reztoAuth: ReztoAuth, fromDate: string, toDate: string, page: number, pageLimit: number) => void;
}

export interface OrderSlice {
    orderStateSlice: OrderState
}


export const createOrderSlice: StoreSlice<OrderSlice> = (set, get) => ({
    orderStateSlice: {
        orders: [],
        selectedReceiptInterval: undefined,

        fetchOrders: (restaurantId: string, reztoAuth: ReztoAuth, date: string) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/orders/paid/' + restaurantId + "?page=0&date=" + date, {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        orderStateSlice: {
                            ...prev.orderStateSlice,
                            orders: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        fetchOrdersInterval: (restaurantId: string, reztoAuth: ReztoAuth, fromDate: string, toDate: string, page: number, pageLimit: number) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/orders/paid/' + restaurantId
                + `/interval/pagination?page=${page}&pageLimit=${pageLimit}&fromDate=${fromDate}&toDate=${toDate}`, {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        orderStateSlice: {
                            ...prev.orderStateSlice,
                            orders:  result.data,
                            selectedReceiptInterval: {fromDate: fromDate, toDate: toDate}
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        fetchOrdersIntervalPagination: (restaurantId: string, reztoAuth: ReztoAuth, fromDate: string, toDate: string, page: number, pageLimit: number) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/orders/paid/' + restaurantId
                + `/interval/pagination?page=${page}&pageLimit=${pageLimit}&fromDate=${fromDate}&toDate=${toDate}`, {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        orderStateSlice: {
                            ...prev.orderStateSlice,
                            orders:  [...prev.orderStateSlice.orders, ...result.data],
                            selectedReceiptInterval: {fromDate: fromDate, toDate: toDate}
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        }
    }
});
