import React from 'react';
import {useForm} from 'react-hook-form';

import InputField from '../../common/InputField';
import {assertTokenValidity} from "../../common/ReztoToken";
import {userReztoStore} from "../../state/rootStore";

export default function FlowCategoryForm({flowId, setCreateDialogOpen}:
                                             { flowId: string, setCreateDialogOpen: any }) {
  
    const {reztoAuth, flowStateSlice} = userReztoStore();

    const {control, handleSubmit} = useForm({
        defaultValues: {}
    })

    const onSub = (data: any) => {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.createFlowCategory( flowId,
                    {
                        name: data.name,
                        displayOrder: 0,
                        select: false,
                        minSelect: data.minValue,
                        maxSelect: data.maxValue
                    },
                    reztoAuth);
            });

        setCreateDialogOpen(false)

    }

    return (
        <div className="p-5">
            <form onSubmit={handleSubmit(onSub)}>

                <InputField name="name" title="Namn" control={control}/>
                <InputField name="minValue" title="Min värde" control={control}/>
                <InputField name="maxValue" title="Max värde" control={control}/>
                <div className="flex flex-row justify-between mt-10">
                    <button type="button"
                            onClick={() => setCreateDialogOpen(false)}
                            className="dropdown-button">Avbryt
                    </button>
                    <button type="submit" className="dropdown-button">Spara</button>
                </div>

            </form>

        </div>
    )
}
