import React, {useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import './RestaurantDropDownMenu.css';
import OutsideClick from "../common/OutsideClick";
import {useWindowSize} from "../drawer/ReztoDrawer";
import {ResetStore, userReztoStore} from "../state/rootStore";
import {Restaurant, RestaurantChain} from "../Model/RestaurantChainAggregate";
export default function RestaurantDropDownMenu() {


    const {restaurantId} = useParams<{ restaurantId: string }>();
    const {restaurantChainAggregateState} = userReztoStore();
    const restaurant: Restaurant | undefined = restaurantChainAggregateState.restaurants.find((r: Restaurant) => r.id === restaurantId)
    const restaurantChain: RestaurantChain | undefined = restaurantChainAggregateState.restaurantChains.find((r: Restaurant) => r.id === restaurant?.restaurantChainId)
    const history = useHistory();
    const size = useWindowSize();
    const [showRestaurantMenu, setShowRestaurantMenu] = useState(false);
    function clickedOutside() {
        setShowRestaurantMenu(false);
    }


    function onMenuButtonClicked(){
        if(restaurantChain && size.width < 500){
            history.push("/home")
        }else{
            setShowRestaurantMenu(true)
        }
    }

    return (
        <>
            <div onClick={() => onMenuButtonClicked()} className="flex flex-row justify-between
            items-center content-center cursor-pointer hover:bg-gray-300 rounded-md p-3">
                <span className=" mt-1 text-normal text-xl text-center">{restaurant?.name}</span>
                <div className="flex flex-col">
                    <svg height={25} width={25}
                         className="chevron-normal"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor"
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="6 9 12 15 18 9"/>
                    </svg>
                    <svg height={25} width={25}
                         className="chevron-rotated"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor"
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="6 9 12 15 18 9"/>
                    </svg>
                </div>
            </div>
            <OutsideClick clicked={clickedOutside}>
                    {showRestaurantMenu &&
                        <ul className="vertical-nav">
                            {
                                restaurantChainAggregateState.restaurantChains.map((restaurantChain: RestaurantChain) => {
                                    return (
                                        <li className="restaurant-chain-list-item" key={restaurantChain.id}>

                                            <Link to={"/restaurant-chains/" + restaurantChain.id}>
                                                <span className="text-normal">{restaurantChain.name.length > 25
                                                    ? restaurantChain.name.substring(0, 20) + "..." : restaurantChain.name}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                {
                                                    restaurantChainAggregateState.restaurants.filter((restaurant: Restaurant) =>
                                                        restaurant.restaurantChainId === restaurantChain.id).map((restaurant: Restaurant) => {
                                                        return (
                                                            <li className="restaurant-list-item font-bold" key={restaurant.id} onClick={() => {
                                                                ResetStore();
                                                                history.push("/restaurants/" + restaurant.id + "/dashboard");
                                                                setShowRestaurantMenu(false);
                                                            }}>
                                                                <span className="text-normal">{restaurant.name}</span>

                                                            </li>)
                                                    })
                                                }

                                            </ul></li>

                                    );
                                })
                            }
                        </ul>
                    }
            </OutsideClick>
        </>

    )
}
