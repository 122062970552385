import {StoreSlice} from "./rootStore";
import {Cashier} from "../Model/Cashier";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";

interface CashierState {
    cashiers: Cashier[];
    fetchCashiers: (restaurantId: string, reztoAuth: ReztoAuth) => void;
    createCashier: (cashier: any, reztoAuth: ReztoAuth) => void;
    deleteCashier: (cashierId: string, reztoAuth: ReztoAuth) => void;
}

export interface ICashierSlice {
    cashierState: CashierState;
}


export const createCashierSlice: StoreSlice<ICashierSlice> = (set, get) => ({
    cashierState: {
        cashiers: [],
        fetchCashiers: (restaurantId, reztoAuth) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/cashiers/' + restaurantId, {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        cashierState: {
                            ...prev.cashierState,
                            cashiers: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createCashier: (cashier: any, reztoAuth: ReztoAuth) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.post( process.env.REACT_APP_ENGINE_BASE_URL + '/cashiers', cashier, {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        cashierState: {
                            ...prev.cashierState,
                            cashiers:[...prev.cashierState.cashiers, result.data]
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        deleteCashier: (cashierId: string, reztoAuth: ReztoAuth) => {
            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.delete( process.env.REACT_APP_ENGINE_BASE_URL + '/cashiers/' + cashierId, {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({
                        cashierState: {
                            ...prev.cashierState,
                            cashiers:[...prev.cashierState.cashiers.filter((cashier: Cashier) => cashier.id !== cashierId)]
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
    }
});
