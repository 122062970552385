import { default as React } from 'react';
import {Link, useParams} from 'react-router-dom';
import { Flow } from "../Model/Flow";

export default function FlowCard({ flow }: { flow: Flow }) {

    const { restaurantId } = useParams<{ restaurantId: string }>();

    return (
        <Link
            to={"/restaurants/" + restaurantId + "/flows/" + flow.id}>
        <div key={flow.id}
            className="max-w-sm bg-gray-200 h-40 rounded-3xl overflow-hidden shadow-lg w-64 transform duration-100 hover:scale-105">
            <div className="flex flex-col p-5 justify-center items-center h-full">
                <div className="text-subheader text-2xl mb-2"> {flow.displayTitle}</div>
                <div className="text-normal text-xl mb-2"> {flow.flowCategories.length} flöden</div>
            </div>
        </div>
        </Link>
    )
}
