import 'firebase/storage';
import React from 'react';
import { useParams } from "react-router-dom";
import {Menu, MenuModel} from "../Model/Menu";
import './menu.css';
import MenuCard from "./MenuCard";
import MenuModelHeader from "./MenuModel/MenuModelHeader";
import {userReztoStore} from "../state/rootStore";

function MenuModelPage() {
    let { menuModelId } = useParams<{ menuModelId: string }>();
    const {menuModelStateSlice} = userReztoStore();
    let menuModels = menuModelStateSlice.menuModels;

    let menuModel = menuModels.find((menu: MenuModel) => menu.id === menuModelId);


    if (!menuModel) {
        return (<></>);
    }

    return (
        <>
            <div>
                <MenuModelHeader />
                <div className="flex flex-col justify-center gap-10 mt-10">
                    {
                        menuModel.menus.map((menu: Menu, index: number) => {
                            return (<MenuCard index={index} key={menu.id} menuId={menu.id} />)
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default MenuModelPage;
