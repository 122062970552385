import React, {useEffect, useState} from 'react'
import ProductSelectionCard from '../../common/ProductSelection/ProductSelectionCard';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import {Flow, FlowCategory, FlowProduct} from "../../Model/Flow";
import {assertTokenValidity} from "../../common/ReztoToken";
import {userReztoStore} from "../../state/rootStore";
import {useForm} from '@mantine/form';
import ReztoSwitch from "../../common/ReztoSwitch";

export default function FlowProductForm({
                                            flowId, flowCategoryId
                                            , setCreateDialogOpen, flowProduct
                                        }:
                                            {
                                                flowId: string, flowCategoryId: string,
                                                setCreateDialogOpen: any,
                                                flowProduct?: FlowProduct
                                            }) {
    const {reztoAuth, flowStateSlice} = userReztoStore();
    const [checked, setChecked] = useState(false);
    const flows = flowStateSlice.flows;
    const [deleteMode, setDeleteMode] = useState(false);

    const form = useForm({
        initialValues: {
            flowProduct,
            checked
        }
    });


    function publish(checked: boolean) {
        if (flowProduct) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    flowStateSlice.publishFlowProduct(flowId, flowCategoryId, flowProduct.id,
                        reztoAuth, !checked);
                });
        }
    }


    useEffect(() => {
        if (flowProduct) {
            setChecked(flowProduct?.includedInMeal);
            form.setFieldValue('checked', flowProduct.includedInMeal);
        }

    }, [flowProduct]);

    function deleteFlowProduct() {
        if (flowProduct) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    flowStateSlice.deleteFlowProduct(flowId, flowCategoryId, flowProduct.id,
                        reztoAuth);
                });
        }
    }


    const log = (data: any) => {
        if (flowProduct && data.product) {
            let flowToUpdate = {...flows.find((flow: Flow) => flow.id === flowId)};
            let flowProductToUpdate = flowToUpdate.flowCategories!
                .find((flowCategory: FlowCategory) => flowCategory.id === flowCategoryId)!.flowProducts
                .find((fp: FlowProduct) => fp.id === flowProduct.id)

            flowProductToUpdate!.includedInMeal = checked;
            flowProductToUpdate!.productId = data.product.id;

            if (flowProductToUpdate.productId) {
                assertTokenValidity(reztoAuth)
                    .then((_) => {
                        flowStateSlice.editFlow(flowToUpdate,
                            flowId,
                            reztoAuth);
                        setCreateDialogOpen(false)
                    });
            }
        } else {
            if (data.product) {
                assertTokenValidity(reztoAuth)
                    .then((_) => {
                        flowStateSlice.createFlowProduct(flowId,
                            flowCategoryId,
                            {
                                productId: data.product.id,
                                displayOrder: 0,
                                isIncludedInMeal: checked
                            },
                            reztoAuth);
                        setCreateDialogOpen(false)
                    });
            }
        }

    }


    return (
        <div className="p-5 ">
            {!deleteMode &&
                <form className="h-full" onSubmit={form.onSubmit((values) => log(values))}>
                    <div className="flex flex-col ">

                        <div className="h-full pl-20 pr-20 pt-2">
                            <ProductSelectionCard prevProductId={flowProduct?.productId}
                                                  form={form}/>
                        </div>

                        <div className="flex flex-row justify-between mt-5">
                            <div className="flex flex-row ">

                                {flowProduct &&
                                    <>
                                        <h1 className="text-normal">Publicerad</h1>
                                        <ReztoSwitch defaultChecked={flowProduct.published} callback={publish}/>
                                    </>
                                }

                            </div>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={checked}
                                                                   onChange={() => {
                                                                       setChecked(!checked);
                                                                   }}/>} style={{color: 'black'}}
                                                  label="Inkluderad i mål"/>
                            </FormGroup>

                        </div>

                        <button className="dropdown-button">Spara</button>

                    </div>
                    {flowProduct &&
                        <div className="flex flex-row justify-center mt-2">
                            <button onClick={() => setDeleteMode(true)} className="dropdown-button">Radera</button>
                        </div>
                    }
                </form>

            }

            {deleteMode &&
                <div className="flex flex-col justify-start items-center h-full">
                    <h1 className="text-normal text-2xl bold pb-5 mt-20">Vill du verkligen radera flödesprodukten?</h1>
                    <button onClick={() => {
                        deleteFlowProduct();
                    }} className="dropdown-button mb-5 w-40">Ja
                    </button>
                    <button onClick={() => {
                        setDeleteMode(false)
                    }} className="dropdown-button">Avbryt
                    </button>
                </div>

            }
        </div>
    )
}
