import React from 'react'
import {Kiosk} from "../Model/Kiosk";
import {Report} from "../Model/Report";
import {userReztoStore} from "../state/rootStore";

export default function ReportFilter(
    {setFilteredReports, originalReports}: { setFilteredReports: any, originalReports: Report[] }) {


    const {kioskStateSlice} = userReztoStore();
    const kiosks = kioskStateSlice.kiosks;

    return (
        <select className="dropdown-button h-10 mt-3" onChange={event => {
            if (event.target.value === "") {
                setFilteredReports(originalReports)
            } else {
                setFilteredReports(
                    originalReports.filter((report: Report) => report.kioskId === event.target.value))
            }

        }}>
            <option value="">Alla kassor</option>
            {
                kiosks.map((kiosk: Kiosk) => {
                    return <option key={kiosk.id} value={kiosk.id}>{kiosk.name}</option>
                })
            }
        </select>
    )
}
